import { AxiosInstance, AxiosRequestConfig } from "axios";

const MAX_RETRY_COUNT = 5;

export const makeApiCall = async <T>(
	api: AxiosInstance,
	config: AxiosRequestConfig
): Promise<T> => {
	let retryCount = 0;

	while (retryCount < MAX_RETRY_COUNT) {
		try {
			const response = await api.request<T>(config);
			return response.data;
		} catch (error) {
			retryCount++;
			if (retryCount === MAX_RETRY_COUNT) {
				throw error; // Throw error after maximum retry attempts
			}
			console.log(
				`Request failed. Retrying (${retryCount}/${MAX_RETRY_COUNT})...`
			);
		}
	}

	throw new Error("Maximum retry attempts reached.");
};
