import { StatsState } from "../utils/types";
import { StatsActions } from "./actionsContants";
import { StatsActionType } from "./StatsAction";

const initialState: StatsState = {
	likes: 0,
	shares: 0,
	views: 0,
};

export const statsReducer = (
	state = initialState,
	action: StatsActionType
): StatsState => {
	switch (action.type) {
		case StatsActions.updateLikes: {
			const { payload } = action;
			return { ...state, likes: payload };
		}

		case StatsActions.updateShares: {
			const { payload } = action;
			return { ...state, shares: payload };
		}

		case StatsActions.updateViews: {
			const { payload } = action;
			return { ...state, views: payload };
		}

		default:
			return state;
	}
};
