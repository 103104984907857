import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgCounterNext = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 71 62"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M56.833 30.995a25.834 25.834 0 1 0-15.055 23.487 2.584 2.584 0 0 1 2.154 4.692A31 31 0 1 1 62 30.994h-5.167Z"
			fill="currentColor"
		/>
		<path
			d="M49.259 30.995h20.315a1.292 1.292 0 0 1 .992 2.118L60.41 45.307a1.292 1.292 0 0 1-1.984 0L48.267 33.112a1.292 1.292 0 0 1 .992-2.118ZM24.228 38.76V23.198l1.354 1.413h-4.87V22h6.605v16.76h-3.089Z"
			fill="currentColor"
		/>
		<path
			d="M36.13 39a12 12 0 0 1-3.446-.503c-1.11-.351-2.044-.83-2.804-1.436l1.307-2.419c.602.495 1.33.894 2.186 1.197a8.18 8.18 0 0 0 2.709.455c1.093 0 1.948-.231 2.566-.694.634-.463.95-1.085.95-1.868 0-.526-.134-.99-.404-1.388-.253-.4-.712-.703-1.378-.91-.65-.208-1.544-.311-2.685-.311h-4.015L31.97 22h9.766v2.61h-8.626l1.616-1.485-.641 6.824-1.616-1.46h3.35c1.648 0 2.97.223 3.968.67 1.014.431 1.751 1.038 2.21 1.82.476.766.713 1.644.713 2.634 0 .973-.237 1.867-.713 2.681-.475.815-1.204 1.469-2.186 1.964-.966.495-2.194.742-3.683.742Z"
			fill="#fff"
		/>
	</svg>
);

export default SvgCounterNext;
