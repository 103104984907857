import React, { useEffect, useRef, useState } from "react";
import {
	List,
	ListRowProps,
	AutoSizer,
	CellMeasurer,
	CellMeasurerCache,
	ScrollParams,
} from "react-virtualized";
import { Icon } from "@iconify/react";

import stylesheet from "./MessageList.module.scss";
import { useChatContext } from "../../providers/ChatProvider";
import { formatRelativeTime } from "../../utils/formattersUtils";
import { generateColor } from "../../utils/generateColor";
import MessageItem from "../MessageItem/MessageItem";
import { UserMessage } from "../../types";
import { ModerationReason } from "../../hooks/useAgoraSignaling";
import MessageContextMenu from "../MessageContextMenu/MessageContextMenu";
import { getTextColor } from "../../utils/colorUtils";
import { useTranslation } from "react-i18next";

const {
	ListWrapper,
	InfiniteListWrapper,
	ScrollBackButton,
	EmptyListWrapper,
	IconWrapper,
	TextWrapper,
	Text,
	SubText,
	hideScrollbar,
} = stylesheet;

const cache = new CellMeasurerCache({
	defaultHeight: 60,
	fixedWidth: true,
});

const MessageList: React.FC = () => {
	const { t } = useTranslation();
	const { messageList, getChatConfig } = useChatContext();
	const { mainColor } = getChatConfig();
	const listRef = useRef<List>(null);
	const [autoScrollEnable, setAutoScrollEnable] = useState(true);

	const messageRenderer = ({ key, index, style, parent }: ListRowProps) => {
		const message = messageList[index];
		return (
			<CellMeasurer
				key={key}
				parent={parent}
				cache={cache}
				columnIndex={0}
				rowIndex={index}
			>
				<MessageItem style={style} message={message} />
			</CellMeasurer>
		);
	};

	const emptyListRenderer = () => {
		return (
			<div className={EmptyListWrapper}>
				<Icon
					className={IconWrapper}
					icon="system-uicons:speech-bubble"
					hFlip={true}
				/>
				<div className={TextWrapper}>
					<span className={Text}>{t("components.chat.title")}</span>
					<span className={SubText}>{t("components.chat.subtitle")}</span>
				</div>
			</div>
		);
	};

	const handleOnScroll = ({ scrollTop, clientHeight }: ScrollParams) => {
		if (listRef.current) {
			const currentOffset = listRef.current.getOffsetForRow({
				index: messageList.length,
			});
			if (scrollTop === currentOffset) {
				if (!autoScrollEnable) setAutoScrollEnable(true);
			} else {
				if (autoScrollEnable) setAutoScrollEnable(false);
			}
		}
	};

	const scrollBackToBottom = () => {
		if (listRef.current) {
			listRef.current.scrollToRow(messageList.length);
		}
	};

	useEffect(() => {
		if (autoScrollEnable) {
			scrollBackToBottom();
		}
	}, [messageList]);

	return (
		<div className={ListWrapper}>
			<div className={InfiniteListWrapper}>
				<AutoSizer>
					{({ height, width }) => (
						<List
							className={hideScrollbar}
							ref={listRef}
							height={height}
							width={width}
							rowHeight={cache.rowHeight}
							deferredMeasurementCache={cache}
							rowCount={messageList.length}
							rowRenderer={messageRenderer}
							onScroll={handleOnScroll}
							noRowsRenderer={emptyListRenderer}
						/>
					)}
				</AutoSizer>
			</div>
			{!autoScrollEnable && (
				<button
					style={{ backgroundColor: mainColor, color: getTextColor(mainColor) }}
					className={ScrollBackButton}
					onClick={scrollBackToBottom}
				>
					{t("components.chat.scroll_tag")}
				</button>
			)}
			<MessageContextMenu />
		</div>
	);
};

export default MessageList;
