import { useContext, useEffect, useReducer, useState } from "react";
import { DataContext } from "./context/DataContext";
import { DataReducer } from "./context/DataReducer";
import Player from "./components/Player/Player";
import ProductList from "./components/ProductList/ProductList";
import {
	createConnection,
	getCurrentSummary,
	getGeoData,
	getLiveData,
	getVideoData,
	sendLike,
	sendLikeVOD,
	updateConnection,
} from "./utils/actions";
import MessagePopUp from "./components/MessagePopUp/MessagePopUp";

import { getProducts } from "./utils/actions";
import "./styles/index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
	updateLikesActionCreator,
	updateSharesActionCreator,
	updateViewsActionCreator,
} from "./store/StatsAction";
import { updateScreenWidthActionCreator } from "./store/LayoutActions";
import {
	Broadcast,
	ProductType,
	RootState,
	SocketActivateProductResponse,
	SocketConnectionResponse,
	SocketNewProductResponse,
	SocketProductResponse,
	SocketRemoveProductResponse,
	VODType,
} from "./utils/types";
import { AxiosResponse } from "axios";
import { getLocation } from "./utils/getLocation";
import { connectToRSocket } from "./services/rsocket-api";
import {
	EventStatus,
	INITIAL_STATE_LIVE,
	ProductStatus,
} from "./utils/constants";
import { PlayerLoadingState } from "./components/PlayerLoadingState/PlayerLoadingState";
import { PlayerProvider } from "./utils/PlayerContext";
import { addUserToLS, getUser } from "./utils/chatUserActions";
import { generateUID } from "./utils/generateUniqueId";
import { ChatUser } from "./types/Chat";
import { getColor } from "./utils/getColor";
import { ChatModes, ChatProvider } from "./FiraChat/providers/ChatProvider";
import FiraChat from "./FiraChat/FiraChat";
import { createBalloons } from "./utils/heart-animation/HeartAnimation";
import { getRandomNumber } from "./utils/getRandomNumber";
import FiraShoppingCart from "./FiraShoppingCart/FiraShoppingCart";
import ModuleTab from "./components/ModuleTab/ModuleTab";
import useSocket, { SocketEvents } from "./hooks/useSocket";
import { CartProvider } from "./context/CartContext";
import { PlayerStateProvider } from "./context/PlayerStateProvider";

const urlParams = new URLSearchParams(window.location.search);
const fira_key = urlParams.get("firakey") || "";
const fira_src = urlParams.get("firasrc") || "";
const shopping_cart = urlParams.get("shoppingCart") || "";
const shareLink =
	urlParams.get("shareLink") && urlParams.get("shareLink") != ""
		? urlParams.get("shareLink")?.split("___").join("&")
		: getLocation();

function App() {
	const { likes, screenW } = useSelector((state: RootState) => ({
		likes: state.stats.likes,
		screenW: state.layout.screenWidth,
	}));
	const [data, dispatch] = useReducer(DataReducer, INITIAL_STATE_LIVE);
	const reduxDispatch = useDispatch();
	const [loaded, setLoaded] = useState(false);
	const [existChat, setExistChat] = useState(false);
	const [isVod, setIsVod] = useState(false);
	const [urlRecording, setUrlRecording] = useState("");
	const [urlEventPreview, setUrlEventPreview] = useState("");
	const [status, setStatus] = useState({
		currentStatus: "",
		scheduledDate: "",
		storeLogo: "",
		mainColor: "",
	});
	const [displayPopUp, setDisplayPopUp] = useState(false);
	const [isVideoLoading, setIsVideoLoading] = useState(false);
	const [showCart, setShowCart] = useState<boolean>(false);
	const [userId, setUserId] = useState<string>();

	const updateProducts = async () => {
		await getProducts(fira_src, dispatch);
	};

	const setProducts = async (products: ProductType[]) => {
		const productsActive = products.filter(
			(product: ProductType) => product.status === ProductStatus.ACTIVE
		);

		dispatch({
			type: "@UPDATE_PRODUCTS",
			payload: { products: productsActive },
		});
	};

	const addProduct = (product: ProductType) => {
		dispatch({
			type: "@ADD_PRODUCT",
			payload: { product },
		});
	};

	const removeProduct = (id: string) => {
		dispatch({
			type: "@REMOVE_PRODUCT",
			payload: { id },
		});
	};

	const addPopup = (id: string) => {
		dispatch({
			type: "@ADD_POPUP",
			payload: { id },
		});
	};

	const removePopup = (id: string) => {
		dispatch({
			type: "@REMOVE_POPUP",
			payload: { id },
		});
	};

	const closeLive = async () => {
		setIsVideoLoading(false);
		dispatch({
			type: "@UPDATE_STATUS",
			payload: { status: "DONE" },
		});
	};

	function handleResize() {
		reduxDispatch(updateScreenWidthActionCreator(window.innerWidth));
	}

	const updateCurrentSummary = async (liveBroadcastingId: string) => {
		const currentSummary = await getCurrentSummary(liveBroadcastingId);
		reduxDispatch(
			updateLikesActionCreator(
				currentSummary?.likes ? currentSummary?.likes : 0
			)
		);
		reduxDispatch(
			updateSharesActionCreator(
				currentSummary?.shares ? currentSummary?.shares : 0
			)
		);
		reduxDispatch(
			updateViewsActionCreator(
				currentSummary?.connections ? currentSummary?.connections : 0
			)
		);
	};

	const requestConnection = async (
		userIdGenerated: string,
		parentId: string
	) => {
		const result = await createConnection(fira_src, userIdGenerated, parentId);
		dispatch({
			type: "@UPDATE_CONNECTION",
			payload: { ...result },
		});

		// if (queues.connectionId && queues.connectionId !== "") {
		// 	if (!queues.queues) {
		// 		updateQueues(queues);
		// 	}
		// }
	};

	const updateQueues = async (queues: any) => {
		const queuesResponse = await updateConnection(
			fira_src,
			queues.connectionId
		);
		dispatch({
			type: "@UPDATE_QUEUES",
			payload: { queues: queuesResponse.data },
		});
	};

	const handleRSocketConnection = async (data: any) => {
		dispatch({
			type: "@UPDATE_VOD",
			payload: { vod: data },
		});
		setProducts(data.vod.products);
	};

	const handleUserGeneration = (broadcast: any) => {
		const currentUser = getUser(fira_src);
		if (currentUser) {
			setUserId(currentUser.id);
		} else {
			const anonUserId = generateUID();
			const newAnonUser: ChatUser = {
				id: anonUserId,
				name: `anon`,
				banned: false,
				color: getColor(),
				role: "fira_audience",
			};
			addUserToLS(newAnonUser, fira_src, true);
			setUserId(newAnonUser.id);
		}
	};

	const handlePlayerStart = async () => {
		const res: AxiosResponse = await getLiveData(fira_src);
		if (res.status == 200) {
			const dBroadcast = res.data;
			let eventTeaser = null;
			let eventPreviewImage = null;
			let existEventTeaser = false;
			let existEventPreviewImage = false;
			if (
				dBroadcast.status === "SCHEDULED" ||
				dBroadcast.status === "NOT_STARTED"
			) {
				setIsVideoLoading(false);
				eventTeaser = dBroadcast.eventTeaser;
				eventPreviewImage = dBroadcast.eventPreviewImage;
				existEventTeaser = eventTeaser && eventTeaser !== "";
				existEventPreviewImage = eventPreviewImage && eventPreviewImage !== "";
				if (existEventTeaser) {
					setUrlRecording(eventTeaser);
				} else if (existEventPreviewImage) {
					setUrlEventPreview(eventPreviewImage);
				}
				handleUserGeneration(dBroadcast);
			}

			setStatus({
				currentStatus: dBroadcast.status,
				scheduledDate: dBroadcast.scheduledDate,
				storeLogo: dBroadcast.storeLogo,
				mainColor: dBroadcast.firaWebConfiguration?.mainColor,
			});

			if (
				dBroadcast.status === "STARTED" ||
				dBroadcast.status === "DONE" ||
				((dBroadcast.status === "SCHEDULED" ||
					dBroadcast.status === "NOT_STARTED") &&
					(existEventTeaser || existEventPreviewImage))
			) {
				dispatch({
					type: "@UPDATE_ALL",
					payload: {
						fira_src: fira_src,
						fira_key: fira_key,
						broadcast: dBroadcast,
						products: [],
						queues: {
							connectionId: "",
							liveBroadcastingId: "",
							userName: "",
							queues: null,
						},
						vod: null,
						dispatch: dispatch,
						shareLink: shareLink || "",
					},
				});
				setLoaded(true);

				if (dBroadcast.status === "STARTED") {
					// New way of generate the old connectionId in the player to be saved on backend
					const currentUser = getUser(fira_src);
					if (currentUser) {
						setUserId(currentUser.id);
						requestConnection(currentUser.id, dBroadcast.parentId);
					} else {
						const anonUserId = generateUID();
						const newAnonUser: ChatUser = {
							id: anonUserId,
							name: `anon`,
							banned: false,
							color: getColor(),
							role: "fira_audience",
						};
						addUserToLS(newAnonUser, fira_src, true);
						setUserId(newAnonUser.id);
						requestConnection(newAnonUser.id, dBroadcast.parentId);
					}
					//////////

					if (dBroadcast.firaWebConfiguration.globalCountersView) {
						updateCurrentSummary(dBroadcast.parentId);
					} else {
						updateCurrentSummary(fira_src);
					}
					updateProducts();
					setExistChat(true);
				}

				if (dBroadcast.status === "DONE") {
					try {
						const videoData = await getVideoData(fira_src);
						if (videoData.visibility === "HIDE") {
							setIsVideoLoading(false);
							return;
						}
						const geodata = await getGeoData();

						// TODO: new IP retrival endpoint
						connectToRSocket(
							geodata.ip,
							videoData.storeId,
							videoData.id,
							handleRSocketConnection
						);
						setIsVod(true);
					} catch (err) {
						setIsVideoLoading(false);
						setExistChat(false);
						return;
					}
				}

				handleUserGeneration(dBroadcast);
			} else {
				setLoaded(true);
			}
		}
	};

	const handleData = async () => {
		try {
			handleResize();
			setIsVideoLoading(true);
			if (fira_src === "NOT_FOUND") {
				setIsVideoLoading(false);
				setStatus(state => ({ ...state, currentStatus: "NOT_FOUND" }));
				setLoaded(true);
			} else {
				handlePlayerStart();
			}
		} catch (error: any) {
			setIsVideoLoading(false);
			setLoaded(true);
			setStatus(state => ({ ...state, currentStatus: "NOT_FOUND" }));
		}
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		handleData();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const onLikeRecieve = (data: number) => {
		reduxDispatch(updateLikesActionCreator(data));
	};

	const onConnectionRecieve = (data: SocketConnectionResponse) => {
		reduxDispatch(updateViewsActionCreator(parseInt(data.count, 10)));
	};

	const onNewProductRecieve = (data: SocketNewProductResponse) => {
		Object.keys(data.products).forEach(key => {
			addProduct(data.products[parseInt(key)]);
		});
	};

	const onRemoveProductRecieve = (data: SocketRemoveProductResponse) => {
		removeProduct(data.id);
	};

	const onActivateProductRecieve = (data: ProductType) => {
		addProduct(data);
	};

	const onAddPopupRecieve = (data: SocketProductResponse) => {
		addPopup(data.broadcastingProductId);
	};

	const onRemovePopupRecieve = (data: SocketProductResponse) => {
		removePopup(data.broadcastingProductId);
	};

	const onClosePlayerRecieve = () => {
		closeLive();
	};

	const handleOnMessage = (event: MessageEvent) => {
		const receivedEvent = JSON.parse(event.data);
		switch (receivedEvent.type) {
			case SocketEvents.LIKE:
				onLikeRecieve(parseInt(receivedEvent.content, 10));
				break;
			case SocketEvents.CONN:
				onConnectionRecieve(receivedEvent.content);
				break;
			case SocketEvents.NEW_PRODUCT:
				onNewProductRecieve(receivedEvent.content);
				break;
			case SocketEvents.REMOVE_PRODUCT:
				onRemoveProductRecieve(receivedEvent.content);
				break;
			case SocketEvents.ACTIVE_PRODUCT:
				onActivateProductRecieve(receivedEvent.content);
				break;
			case SocketEvents.ADD_POPUP:
				onAddPopupRecieve(receivedEvent.content);
				break;
			case SocketEvents.REMOVE_POPUP:
				onRemovePopupRecieve(receivedEvent.content);
				break;
			case SocketEvents.CLOSE_PLAYER:
				onClosePlayerRecieve();
				break;
			case SocketEvents.START_PLAYER:
				handlePlayerStart();
				break;
		}
	};

	const { startConnection, cleanUp } = useSocket(handleOnMessage);

	useEffect(() => {
		if (
			userId &&
			(status.currentStatus === "STARTED" ||
				status.currentStatus === "SCHEDULED" ||
				status.currentStatus === "NOT_STARTED")
		) {
			if (
				data.broadcast.firaWebConfiguration.globalCountersView &&
				data.broadcast.parentId
			) {
				startConnection(userId, data.broadcast.parentId);
			} else {
				startConnection(userId, fira_src);
			}
		}

		return () => {
			cleanUp();
		};
	}, [userId, status]);

	const likesUrl = data.broadcast.firaWebConfiguration?.likesUrls || [
		"https://firalivedev.blob.core.windows.net/images/file/defaultlike.png",
	];
	const maxNumber: number = likesUrl.length - 1;
	const vodData: VODType = data.vod?.vod;

	const handleLikes = async () => {
		createBalloons(1, likesUrl[getRandomNumber(0, maxNumber)]);

		if (data.broadcast.status === EventStatus.STARTED) {
			const currentUser = getUser(fira_src);
			if (currentUser) {
				sendLike(
					currentUser.id,
					fira_src,
					data.queues.connectionId,
					data.broadcast.startDate,
					data.broadcast.parentId ? data.broadcast.parentId : undefined
				);
			}
		} else if (data.broadcast.status === EventStatus.DONE) {
			const currentTime = document.querySelector("#fira_player")
				? (document.querySelector("#fira_player") as HTMLVideoElement)
						.currentTime
				: 0;
			sendLikeVOD(
				vodData.id,
				data.vod.connectionId,
				vodData.storeId,
				currentTime
			);
		}
	};

	return (
		<PlayerStateProvider firaKey={fira_key} firaSrc={fira_src}>
			<DataContext.Provider value={data}>
				<CartProvider storeId={fira_key}>
					<ChatProvider
						src={fira_src}
						isGlobal={data.broadcast.isGlobal}
						parentId={data.broadcast.parentId}
						eventStatus={data.broadcast.status}
						eventStartDate={data.broadcast.startDate}
						mode={ChatModes.PLAYER}
						config={data.broadcast.firaWebConfiguration}
					>
						{fira_key && fira_src && loaded ? (
							<div id="app__wrapper">
								{(status.currentStatus === "STARTED" ||
									status.currentStatus === "DONE" ||
									((status.currentStatus == "SCHEDULED" ||
										status.currentStatus == "NOT_STARTED") &&
										(urlRecording !== "" || urlEventPreview !== ""))) && (
									<div className="main_wrapper">
										<div
											className="app__container"
											style={{
												gridTemplateColumns:
													screenW > 981 &&
													(existChat || (isVod && shopping_cart === "true"))
														? "1fr 280px"
														: "",
											}}
										>
											<div className={"video-data__wrapper"}>
												<PlayerProvider>
													<Player
														existChat={existChat}
														urlRecording={urlRecording}
														setUrlRecording={setUrlRecording}
														urlEventPreview={urlEventPreview}
														setDisplayPopUp={setDisplayPopUp}
														displayPopUp={displayPopUp}
														isVideoLoading={isVideoLoading}
														setIsVideoLoading={setIsVideoLoading}
													/>
												</PlayerProvider>
											</div>
											{screenW > 981 &&
												isVod &&
												shopping_cart === "true" &&
												data.products.length > 0 && (
													<div id="chat__wrapper">
														<ModuleTab
															isVod={isVod}
															showCart={showCart}
															onChatClick={() => setShowCart(false)}
															onCartClick={() => setShowCart(true)}
														/>

														<FiraShoppingCart />
													</div>
												)}
											{screenW > 981 && existChat && (
												<div id="chat__wrapper">
													{shopping_cart === "true" &&
														data.products.length > 0 && (
															<ModuleTab
																isVod={isVod}
																showCart={showCart}
																onChatClick={() => setShowCart(false)}
																onCartClick={() => setShowCart(true)}
															/>
														)}
													{shopping_cart === "true" &&
														data.products.length > 0 &&
														(showCart ? (
															<FiraShoppingCart />
														) : (
															<div
																style={{
																	maxHeight: "calc(100% - 42px)",
																	height: "100%",
																}}
															>
																<FiraChat
																	handleLikes={handleLikes}
																	likes={likes}
																/>
															</div>
														))}
													{(shopping_cart === "" ||
														shopping_cart === "false" ||
														(shopping_cart === "true" &&
															data.products.length === 0)) && (
														<FiraChat handleLikes={handleLikes} likes={likes} />
													)}
												</div>
											)}
										</div>
										<ProductList setDisplayPopUp={setDisplayPopUp} />
									</div>
								)}

								{status.currentStatus !== "STARTED" &&
									status.currentStatus !== "DONE" &&
									(status.currentStatus == "SCHEDULED" ||
										status.currentStatus == "NOT_STARTED") &&
									urlRecording == "" &&
									urlEventPreview == "" && (
										<div className="error__container">
											<MessagePopUp status={status} />
										</div>
									)}

								{status.currentStatus == "CANCELLED" && (
									<PlayerLoadingState setAbsolute />
								)}

								{(status.currentStatus === "NOT_FOUND" ||
									status.currentStatus === "ERROR") && (
									<PlayerLoadingState color={"#FFDE07"} />
								)}
							</div>
						) : fira_key && fira_src && !loaded ? (
							<div className="spin-container">
								<div className="spin"></div>
							</div>
						) : (
							<div className="error__container">
								<MessagePopUp
									status={{
										currentStatus: "MISSING_PARAMETERS",
										scheduledDate: "",
										storeLogo: "",
										mainColor: "",
									}}
								/>
							</div>
						)}
					</ChatProvider>
				</CartProvider>
			</DataContext.Provider>
		</PlayerStateProvider>
	);
}

export default App;
