import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgGmailLogo = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 33 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<g clipPath="url(#GmailLogo_svg__a)" fillRule="evenodd" clipRule="evenodd">
			<path
				d="M3.653 4.744V24H2.138A2.143 2.143 0 0 1 0 21.864V1.99l3.653 2.752v.002ZM29.347 4.744V24h1.515C32.038 24 33 23.039 33 21.864V1.99l-3.653 2.752v.002Z"
				fill="#D53B29"
			/>
			<path
				d="M19.684 24H3.654l9.568-6.963L3.653 24l9.568-6.963L19.684 24Z"
				fill="#EEEFEF"
			/>
			<path
				d="m3.653 5.305 12.846 8.88 12.847-8.88v.116L16.5 14.652 3.653 5.422v-.117Z"
				fill="#B4A4A3"
			/>
			<path
				d="M16.5 14.652 3.693 5.451l-.04-.03v1.31l.05.053 9.517 10.253 3.278-2.385Z"
				fill="#CCCFCF"
			/>
			<path d="m3.653 24 9.568-6.963L3.653 6.731V24Z" fill="#E0E2E3" />
			<path
				d="M19.684 24h9.662V5.42L16.5 14.653l-3.278 2.385L19.684 24Z"
				fill="#E4E4E4"
			/>
			<path d="m1.778 0 29.526.005L16.5 9.748 1.778 0Z" fill="#F3F3F3" />
			<path
				d="M2.864.322 16.5 9.747 30.135.322a1.833 1.833 0 0 1 2.54.462c.233.335.337.721.323 1.102l.001.91c-.258.19-.528.348-.785.526L16.5 14.185.785 3.322C.528 3.143.26 2.986 0 2.795v-.909h.001A1.81 1.81 0 0 1 .323.784 1.832 1.832 0 0 1 2.864.322Z"
				fill="#E04C3B"
			/>
			<path
				d="M3.653 5.304 0 2.795l3.653 3.936V5.304ZM29.347 5.304 33 2.795l-3.653 3.936V5.304Z"
				fill="#BA3A28"
			/>
		</g>
		<defs>
			<clipPath id="GmailLogo_svg__a">
				<path fill="#fff" d="M0 0h33v24H0z" />
			</clipPath>
		</defs>
	</svg>
);

export default SvgGmailLogo;
