import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgTelegramLogo = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M32 16c0-8.837-7.163-16-16-16S0 7.163 0 16s7.163 16 16 16 16-7.163 16-16Z"
			fill="url(#TelegramLogo_svg__a)"
		/>
		<path
			d="m23.785 9.204-2.857 14.409s-.4 1-1.5.52l-6.594-5.056-2.398-1.16-4.037-1.358s-.619-.22-.679-.7c-.06-.48.7-.739.7-.739l16.047-6.295s1.32-.58 1.32.38h-.002Z"
			fill="#fff"
		/>
		<path
			d="M12.327 23.451s-.192-.018-.432-.777c-.24-.76-1.458-4.756-1.458-4.756l9.692-6.156s.56-.34.54 0c0 0 .1.06-.2.34-.3.28-7.614 6.855-7.614 6.855"
			fill="#D3E6F2"
		/>
		<path
			d="m15.363 21.015-2.609 2.378s-.204.155-.427.058l.5-4.418"
			fill="#B6D0E5"
		/>
		<defs>
			<linearGradient
				id="TelegramLogo_svg__a"
				x1={4.686}
				y1={4.686}
				x2={27.231}
				y2={27.232}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#42BDE9" />
				<stop offset={1} stopColor="#23A8DD" />
			</linearGradient>
		</defs>
	</svg>
);

export default SvgTelegramLogo;
