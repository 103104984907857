import "./HeartAnimation.scss";

function random(num: number) {
	return Math.floor(Math.random() * num);
}

const heartsDefault =
	"https://firalivedev.blob.core.windows.net/images/file/defaultlike.png";

const times = 1;
// 'infinite'
function getRandomStyles(likeUrl: string) {
	const dur = random(5) + 5;

	return `
		background-image: url(${likeUrl});
		background-repeat: no-repeat;
		background-size: contain;
		animation: float ${dur}s 50s cubic-bezier(0.19, 1.15, 1, 0.09) ${times};
		animation-fill-mode: forwards;
		animation-delay: -0.6s;
		width: 35px;
    `;
}

export const createBalloons = (num: number, image: string = heartsDefault) => {
	const balloonContainer: Element =
		document.querySelector(".heart-container") || ({} as Element);
	for (let i = num; i > 0; i--) {
		const balloon = document.createElement("div");
		balloon.className = `balloon${image ? " image-balloon" : ""}`;
		balloon.style.cssText = getRandomStyles(image);
		balloonContainer.append(balloon);
	}
};
