import { VOD_Data } from "./types";

export const URL_GET_PRODUCTS =
	"/v1/live-broadcast-event/broadcast-product/retrieve-all-activates/";

export const URL_RETRIEVE_ALL_FIRA_TV = "/v1/fira-tv/video/fetch-by-origin/";

export const FIRA_URL_RECORDING =
	"/api/v1/fbe-recording-controller/url-recording/";

export const URL_NEW_BROADCAST_CONNECTION =
	"/v1/live-broadcasting-actions/connection/new";

export const URL_UPDATE_BROADCAST_CONNECTION =
	"/v1/live-broadcasting-actions/connection/call-queues-creation/";

export const URL_UPDATE_SUMMARY =
	"/v1/live-broadcasting-actions/client/current-summary/";

export const URL_NEW_LIKE = "/v1/live-broadcasting-actions/like/";

export const URL_SHARE = "/v1/live-broadcasting-actions/share/";

export const URL_SHOPPING_CART =
	"/v1/live-broadcasting-actions/shopping-cart/new";

export const REGULAR_CLICK = "/v1/live-broadcasting-actions/regular-click/new";

export const POPUP_CLICK = "/v1/live-broadcasting-actions/popup-click/new";

export const emailRegex =
	/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;

export const phoneRegex = /^[0-9()-. ]+$/;

export const ABSTRACT_API_URL = `https://jsonip.com`;

export enum EventStatus {
	SCHEDULED = "SCHEDULED",
	NOT_STARTED = "NOT_STARTED",
	STARTED = "STARTED",
	DONE = "DONE",
}

export enum SocialMedia {
	FACEBOOK = "FACEBOOK",
	TWITTER = "TWITTER",
	INSTAGRAM = "INSTAGRAM",
	LINKEDIN = "LINKEDIN",
	TELEGRAM = "TELEGRAM",
	WHATSAPP = "WHATSAPP",
	GMAIL = "GMAIL",
	URL = "URL",
}

export enum PlayerAction {
	PLAY = "PLAY",
	PAUSE = "PAUSE",
}

export const INITIAL_STATE_LIVE: any = {
	broadcast: {
		currency: "",
		endDate: "",
		eventName: "",
		firaWebConfiguration: {
			cartIconUrl: "",
			disclaimerUrl: "",
			enableChatLogin: 0,
			enableLogin: false,
			enableNicknameModification: false,
			likesUrls: [],
			loadingUrl: "",
			loginForm: "",
			mainColor: "",
			showPrices: false,
			showViews: false,
		},
		firaWebLogin: false,
		language: "",
		startDate: "",
		scheduledDate: "",
		status: "",
		storeLogo: "",
		storeName: "",
		suscriberCredentialsDto: {
			app: "",
			host: "",
			streamName: "",
		},
	},
	products: [],
	fira_key: "",
	fira_src: "",
	shareLink: "",
	queues: {
		connectionId: "",
		liveBroadcastingId: "",
		userName: "",
		queues: null,
	},
	vod: null,
};

export const VOD_INITIAL_STATE: VOD_Data = {
	connectionId: "",
	state: "",
	vod: null,
};

export enum ProductStatus {
	ACTIVE = "ACTIVATE",
	INACTIVE = "DEACTIVATE",
}
