export const TRANSLATIONS_PT = {
	components: {
		loading_state: "Uma nova transmissão começará em breve!",
		header: {
			share: "Compartilhar",
		},
		login_user: {
			title: "Entrar",
			participate: "Participar",
			disclaimer: "Ao registrar sua conta, você aceita nossa",
			disclaimer_alt: "Ao registrar sua conta, você aceita nossas",
			disclaimer_1: "política de tratamento de dados",
			disclaimer_2: "e os",
			disclaimer_3: "Termos e Condições de Serviço.",
			messages: {
				error: "Usuário existente, por favor tente com outros dados.",
			},
			form: {
				user: "Insira seu nome",
			},
			accept: "Acessar",
			cancel: "Cancelar",
		},
		message_popup: {
			not_started: {
				soon: "Começará em breve.",
				hours: "Começará em {{hours}} horas.",
				days: "Começará em {{days}} dias.",
			},
			error: {
				title: "Erro.",
				message: "Algo deu errado, contate o suporte.",
			},
			no_broadcast: {
				title: "Não encontrado.",
				message: "A transmissão não foi encontrada.",
			},
			no_parameters: {
				title: "Parâmetros ausentes.",
				message: "Verifique a URL da transmissão.",
			},
			reconnecting: {
				title: "Reconectando...",
			},
			done: {
				title: "LIVE FINALIZADO...",
			},
			done_error: {
				title: "LIVE FINALIZADO...",
				message: "Houve um erro com o vídeo.",
			},
		},
		share_popup: {
			title: "Compartilhar link",
			share: "Compartilhar",
			copy: "Copiar",
			copyLink: "Copiar link",
			copied: "Copiado",
		},
		video_controls: {
			pip_message_1: "Minimizar",
			pip_message_2: "Vídeo",
			link: "Link",
			copied: "copiado",
		},
		custom_message_input: {
			input: "Escreva uma mensagem...",
		},
		chat: {
			title: "Ainda não há chats",
			subtitle: "Envie uma mensagem para iniciar a conversa.",
			scroll_tag: "Descer",
			input_placeholder: "Escreva uma mensagem...",
			send_button: "Enviar",
			error_title: "Atualize o chat para poder interagir.",
			error_button: "Atualizar",
			disconnect_title: "Evento finalizado",
		},
		product_popup: {
			buy: "Comprar",
			add_cart: "Adicionar ao carrinho",
			added_cart: "Produto adicionado!",
		},
		share_message:
			"Convido você a aproveitar a experiência de live shopping em",
		shopping_cart: {
			buyButtonText: "Continuar compra",
			budget: "Enviar pedido",
			namePlaceholder: "Insira seu nome*",
			empty: "Ainda não há produtos",
		},
	},
};
