import { VOD_Data } from "./types";

const debounce = (
	func: (currentTime: number, vod: VOD_Data) => void,
	delay: number
) => {
	let timerId: NodeJS.Timeout;
	return function (currentTime: number, vod: VOD_Data) {
		if (timerId) {
			clearTimeout(timerId);
		}
		timerId = setTimeout(() => {
			func(currentTime, vod);
		}, delay);
	};
};

export default debounce;
