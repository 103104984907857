import stylesheet from "./ModuleTab.module.scss";
import { Cart } from "../FiraIcons";
import { useChatContext } from "../../FiraChat/providers/ChatProvider";
import { useCart } from "../../context/CartContext";

interface ModuleTab {
	onChatClick(): void;
	onCartClick(): void;
	showCart: boolean;
	isVod: boolean;
}

const ModuleTab = ({
	onCartClick,
	onChatClick,
	showCart,
	isVod,
}: ModuleTab) => {
	const {
		singleColumn,
		multipleColumn,
		ModuleTabWrapper,
		TabItem,
		notificationCart,
		indicatorRight,
		indicator,
		indicatorCart,
	} = stylesheet;
	const { getChatConfig } = useChatContext();
	const { mainColor } = getChatConfig();
	const { cart } = useCart();
	const wrapperClassName = isVod ? singleColumn : multipleColumn;

	return (
		<div className={`${ModuleTabWrapper} ${wrapperClassName}`}>
			{isVod && (
				<div className={TabItem} onClick={onCartClick}>
					<Cart />
					{cart && cart.length > 0 && (
						<div
							style={{ backgroundColor: mainColor }}
							className={notificationCart}
						></div>
					)}
					<div
						style={{ backgroundColor: mainColor }}
						className={indicatorCart}
					></div>
				</div>
			)}
			{!isVod && (
				<>
					<div className={TabItem} onClick={onChatClick}>
						Chat
					</div>
					<div className={TabItem} onClick={onCartClick}>
						<Cart />
						{cart && cart.length > 0 && (
							<div
								style={{ backgroundColor: mainColor }}
								className={notificationCart}
							></div>
						)}
					</div>
					<div
						style={{ backgroundColor: mainColor }}
						className={showCart && !isVod ? indicatorRight : indicator}
					></div>
				</>
			)}
		</div>
	);
};

export default ModuleTab;
