export const openPip = async (video: HTMLVideoElement) => {
	if (video !== document.pictureInPictureElement) {
		await video.requestPictureInPicture();
	} else {
		return;
	}
};

export const closePip = async () => {
	if (document.pictureInPictureElement) {
		await document.exitPictureInPicture();
	}
};

export const doPictureInPicture = (status: string, video: HTMLVideoElement) => {
	const supported = document.pictureInPictureEnabled;
	if (supported) {
		if (!document.pictureInPictureElement) {
			openPip(video);
		}
	}
};
