import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from "react";
import { DataContext } from "../../context/DataContext";
import currencyFormat from "../../utils/currencyFormat";
import "./product.scss";
import noImage from "../../assets/svg/noImage.svg";
import { handleImageLoadError } from "../../utils/handleImageLink";
import { doPictureInPicture } from "../../utils/pipHandler";
import { useDispatch, useSelector } from "react-redux";
import {
	closePopUpActionCreator,
	openPopUpActionCreator,
	ProductClickOrigin,
} from "../../store/PopUpActions";
import { ProductType, RootState, VODType } from "../../utils/types";
import { doClickToProduct, ProductClickType } from "../../utils/actions";
import { getUser } from "../../utils/chatUserActions";

const urlParams = new URLSearchParams(window.location.search);
const fira_src = urlParams.get("firasrc") || "";

type ProductProps = {
	productInfo: ProductType;
	setDisplayPopUp: Dispatch<SetStateAction<boolean>>;
};

export default function Product({
	productInfo,
	setDisplayPopUp,
}: ProductProps) {
	const { broadcast, queues }: any = useContext(DataContext);
	const mainColor = broadcast?.firaWebConfiguration?.mainColor || "#beef00";
	const { currency } = broadcast;
	const [productActive, setProductActive] = useState(productInfo.onPopup);
	const dispatch = useDispatch();
	const popup = useSelector((state: RootState) => state.popup);
	const { product, id } = productInfo;
	const {
		name,
		price1,
		price2,
		price3,
		images,
		directUrl = "#",
		labels,
	} = product;

	const existPrice = parseFloat(price1) > 0 || parseFloat(price2) > 0;

	useEffect(() => {
		setProductActive(productInfo.onPopup);
	}, [productInfo]);

	const handleClick = async () => {
		//Open Pop Up
		if (popup.open) {
			dispatch(closePopUpActionCreator());
			dispatch(
				openPopUpActionCreator(productInfo, ProductClickOrigin.regularProduct)
			);
		} else {
			dispatch(
				openPopUpActionCreator(productInfo, ProductClickOrigin.regularProduct)
			);
		}

		const currentUser = getUser(fira_src);

		if (broadcast.status === "STARTED" && currentUser) {
			doClickToProduct(
				currentUser.id,
				productInfo,
				fira_src,
				queues.connectionId,
				broadcast.startDate,
				ProductClickType.galleryIntention,
				broadcast.parentId ? broadcast.parentId : undefined
			);
		}

		setDisplayPopUp(true);

		//Check if browser supports PIP
		// doPictureInPicture(broadcast.status);
	};

	const handleMouseEnter = () => {
		if (!productInfo.onPopup) {
			setProductActive(true);
		}
	};

	const handleMouseLeave = () => {
		if (!productInfo.onPopup) {
			setProductActive(false);
		}
	};

	return (
		<div className="carousel__element product__wrapper" onClick={handleClick}>
			<div
				className="product__card"
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				style={
					productActive
						? {
								border: `1px solid ${mainColor}`,
						  }
						: {
								border: "1px solid #d5dde0",
						  }
				}
			>
				<div className="img__container">
					<img
						src={images && images[0] ? images[0] : noImage}
						alt={name}
						onError={handleImageLoadError}
					/>
				</div>
				<div
					className="copy__container"
					style={{
						gridTemplateRows: "max-content max-content",
					}}
				>
					{labels && labels.length > 0 && (
						<div className="TagsWrapper">
							{labels.map(label => (
								<div
									key={label.id}
									style={{
										backgroundColor: `#${label.labelColor}`,
										color: `#${label.fontColor}`,
									}}
									className="Label"
								>
									{label.name}
								</div>
							))}
						</div>
					)}
					<div className="title_wrapper">
						<span className="title">{name}</span>
					</div>
					{existPrice && (
						<div className="prices_wrapper">
							<div className="prices_group">
								{parseFloat(price1) > 0 && (
									<span
										className={`price${
											parseFloat(price2) > 0 ? " oldPrice" : ""
										}`}
									>
										{currencyFormat(parseFloat(price1), currency)}
									</span>
								)}
								{parseFloat(price2) > 0 && (
									<span className={`price discountPrice`}>
										{currencyFormat(parseFloat(price2), currency)}
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
