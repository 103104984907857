import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { RootState } from "../utils/types";
import { layoutReducer } from "./LayoutReducer";
import { statsReducer } from "./StatsReducer";
import { credentialsReducer } from "./CredentialsReducer";
import { popUpReducer } from "./PopUpReducer";

const rootReducer = combineReducers<RootState>({
	stats: statsReducer,
	layout: layoutReducer,
	credentials: credentialsReducer,
	popup: popUpReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
