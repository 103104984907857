import React, {
	ChangeEventHandler,
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { useSelector } from "react-redux";
import { DataContext } from "../../context/DataContext";
import { sendLike, sendLikeVOD, sendPlayVOD } from "../../utils/actions";
import { getRandomNumber } from "../../utils/getRandomNumber";
import { createBalloons } from "../../utils/heart-animation/HeartAnimation";
import { RootState, VODType } from "../../utils/types";
import { useTranslation } from "react-i18next";
import {
	Volume,
	Muted,
	Minireproductor,
	FullScreen,
	Message,
	Heart,
	Share,
	Cart,
} from "../FiraIcons";

import "./VideoControls.scss";
import { shortNumberFormat } from "../../utils/shortNumberFormat";
import Play from "../FiraIcons/Play";
import Pause from "../FiraIcons/Pause";
import debounce from "../../utils/debouce";
import { PlayerAction } from "../../utils/constants";
import VideoProgressBar from "../VideoProgressBar/VideoProgressBar";
import { useCart } from "../../context/CartContext";
import { getTextColor } from "../../FiraChat/utils/colorUtils";
import { getUser } from "../../utils/chatUserActions";

const urlParams = new URLSearchParams(window.location.search);
const fira_src = urlParams.get("firasrc") || "";

type VideoType = {
	isVideoMuted: boolean;
	setIsVideoMuted: Dispatch<SetStateAction<boolean>> | null;
	showChat: boolean;
	setShowChat: Dispatch<SetStateAction<boolean>>;
	playerRef: any;
	isVideoPaused: boolean;
	setIsVideoPaused: Dispatch<SetStateAction<boolean>>;
	handlePlay: () => void;
	handleMute: () => void;
	existChat: boolean;
	isVod: boolean;
	shoppingCart: boolean;
	setShowSharePopUp: Dispatch<SetStateAction<boolean>>;
	showSharePopUp: boolean;
	currentTime: number;
	setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
	showPlayControls: boolean;
};

export default function VideoControls({
	isVideoMuted,
	showChat,
	setShowChat,
	playerRef,
	isVideoPaused,
	handlePlay,
	handleMute,
	existChat,
	isVod,
	shoppingCart,
	setShowSharePopUp,
	showSharePopUp,
	currentTime,
	setCurrentTime,
	showPlayControls,
}: VideoType) {
	if (
		!document.pictureInPictureEnabled &&
		document.querySelector(".pip-button")
	) {
		(document.querySelector(".pip-button") as HTMLElement).classList.add(
			"d-none"
		);
	}
	const { t } = useTranslation();
	const screenW = useSelector((state: RootState) => state.layout.screenWidth);
	const stats = useSelector((state: RootState) => state.stats);
	const { broadcast, vod }: any = useContext(DataContext);
	const vodData: VODType = vod?.vod;
	const mainColor = broadcast?.firaWebConfiguration?.mainColor || "#fff";
	const { queues }: any = useContext(DataContext);
	const [activePIP, setActivePIP] = useState(false);
	const [showPIPCopy, setShowPIPCopy] = useState(true);
	const progressBarRef = useRef<any>();
	const { cart } = useCart();

	const likesUrl = broadcast.firaWebConfiguration?.likesUrl || [
		"https://firalivedev.blob.core.windows.net/images/file/defaultlike.png",
	];
	const activeBG = `${mainColor}30`;
	const inactiveBG = "rgba(0, 0, 0, 0.3)";

	const [progress, setProgress] = useState(
		playerRef && playerRef.current
			? (playerRef.current.currentTime / playerRef.current.duration) * 100
			: 0
	);

	const maxNumber = parseInt(likesUrl.length) - 1;

	const handleFullScreen = () => {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else if ((document as Document).fullscreenElement) {
			// Need this to support Safari
			document.exitFullscreen();
		} else if (playerRef.current.requestFullscreen) {
			// Need this to support Safari
			playerRef.current.requestFullscreen();
		} else {
			playerRef.current.requestFullscreen();
		}
	};

	const handlePip = async () => {
		// const videoContainer = document.querySelector(
		// 	"#fira-subscriber"
		// ) as HTMLVideoElement;
		const pipButton = document.querySelector(
			"#videoControls .pip-button"
		) as HTMLButtonElement;

		try {
			if (playerRef.current !== document.pictureInPictureElement) {
				pipButton.disabled = true;
				await playerRef.current.requestPictureInPicture();
				setActivePIP(true);
			} else {
				await document.exitPictureInPicture();
				setActivePIP(false);
			}
		} catch (error) {
			console.log(error);
		} finally {
			pipButton.disabled = false;
		}
	};

	const handleToggleChatDialog = () => {
		const chatDialog = document.querySelector("#ChatDialog") as HTMLElement;
		if (chatDialog.style.display == "block") {
			setShowChat(false);
			chatDialog.style.display = "none";
		} else {
			setShowChat(true);
			chatDialog.style.display = "block";
		}
	};

	const handleToggleCartDialog = () => {
		const chatDialog = document.querySelector("#CartDialog") as HTMLElement;
		if (chatDialog.style.display == "block") {
			chatDialog.style.display = "none";
		} else {
			chatDialog.style.display = "block";
		}
	};

	const handleLike = () => {
		createBalloons(1, likesUrl[getRandomNumber(0, maxNumber)]);
		if (broadcast.status === "STARTED") {
			const currentUser = getUser(fira_src);
			if (currentUser) {
				sendLike(
					currentUser.id,
					fira_src,
					queues.connectionId,
					broadcast.startDate,
					broadcast.parentId ? broadcast.parentId : undefined
				);
			}
		} else {
			const currentTime = (
				document.querySelector("#fira_player") as HTMLVideoElement
			).currentTime;
			sendLikeVOD(vodData.id, vod.connectionId, vodData.storeId, currentTime);
		}
	};

	const handleShare = () => {
		setShowSharePopUp(state => !state);
	};

	const debouncedFunction = useCallback(
		debounce(async (currentTime, vod) => {
			if (
				vod &&
				vod.vod &&
				playerRef &&
				playerRef.current &&
				!playerRef.current.paused
			) {
				const vodData: VODType = vod.vod;
				await sendPlayVOD(
					vodData.id,
					vod.connectionId,
					vodData.storeId,
					PlayerAction.PAUSE,
					currentTime
				);

				const currentTimeNow = document.querySelector("#fira_player")
					? (document.querySelector("#fira_player") as HTMLVideoElement)
							.currentTime
					: 0;
				await sendPlayVOD(
					vodData.id,
					vod.connectionId,
					vodData.storeId,
					PlayerAction.PLAY,
					currentTimeNow
				);
			}
		}, 4000),
		[]
	);

	const updateProgressBar = () => {
		// Obtiene el elemento de video y la barra de progreso
		const video = playerRef?.current;
		const progressBar = progressBarRef?.current;

		if (video && progressBar) {
			// Calcula el porcentaje de progreso del video
			const progress = (video.currentTime / video.duration) * 100;

			// Actualiza el valor de la barra de progreso
			progressBar.value = progress;

			setProgress(progress);
		}
	};

	const updateVideoProgress = () => {
		// Obtiene el elemento de video y la barra de progreso
		const video = playerRef.current;
		const progressBar = progressBarRef.current;

		if (video && progressBar) {
			// Calcula el tiempo actual del video
			const currentTime = video.duration * (parseInt(progressBar.value) / 100);

			// Actualiza el tiempo actual del video
			video.currentTime = currentTime;
			const progressPosition = (currentTime / video.duration) * 100;
			setProgress(progressPosition);
			debouncedFunction(currentTime, vod);
		}
	};

	useEffect(() => {
		const video = playerRef?.current;

		if (video) {
			video.addEventListener("timeupdate", updateProgressBar);
		}

		setTimeout(() => {
			setShowPIPCopy(false);
		}, 9000);
	}, []);

	useEffect(() => {
		if (progressBarRef.current) {
			progressBarRef.current.style.setProperty(
				"--scrollbar-background",
				mainColor
			);
		}
	}, [progressBarRef]);

	return (
		<div
			id="videoControls"
			className={`${broadcast.status === "STARTED" ? "live-video" : ""} ${
				broadcast.status === "SCHEDULED" ? "preview-video" : ""
			}`}
		>
			{screenW > 981 && broadcast.status === "DONE" && (
				<div className="video-control play-button" onClick={handlePlay}>
					{isVideoPaused && (
						<Play
							style={{
								color: "#fff",
								fontSize: "14px",
							}}
						/>
					)}
					{!isVideoPaused && (
						<Pause
							style={{
								color: "#fff",
								fontSize: "14px",
							}}
						/>
					)}
				</div>
			)}
			<div
				className="volumen-control-wrapper"
				style={{
					visibility: broadcast.status == "STARTED" ? "hidden" : "visible",
				}}
			>
				{(broadcast.status === "DONE" ||
					broadcast.status === "SCHEDULED" ||
					broadcast.status === "NOT_STARTED") &&
					screenW > 981 && (
						<div className="volumen-control">
							<input
								type="range"
								id="progress_bar"
								min="0"
								max="100"
								step="0.1"
								value={progress}
								ref={progressBarRef}
								onChange={updateVideoProgress}
								style={{
									background: `linear-gradient(to right, ${mainColor} 0%, ${mainColor} ${progress}%, rgba(255,255,255,0.35) ${progress}%, rgba(255,255,255,0.35) 100%)`,
								}}
							/>
						</div>
					)}
			</div>
			<div className="video-control share-button" onClick={handleShare}>
				<Share
					style={{
						color: showSharePopUp ? mainColor : "#fff",
						fontSize: "20px",
						opacity: 1,
					}}
				/>
			</div>
			<div
				className="video-control volume-button"
				style={
					screenW < 981
						? isVideoMuted
							? { backgroundColor: activeBG }
							: { backgroundColor: inactiveBG }
						: {}
				}
			>
				{!isVideoMuted && (
					<Volume
						onClick={handleMute}
						style={{
							color: "#fff",
							fontSize: "25px",
						}}
					/>
				)}
				{isVideoMuted && (
					<Muted
						onClick={handleMute}
						style={{
							color: mainColor,
							fontSize: "25px",
						}}
					/>
				)}
			</div>

			{screenW > 981 && (
				<div className="video-control fullscreen-button">
					<FullScreen
						onClick={handleFullScreen}
						style={{
							color: "#fff",
							fontSize: "25px",
						}}
					/>
				</div>
			)}

			{"pictureInPictureEnabled" in document && (
				<div
					className="video-control pip-button"
					style={
						screenW < 981
							? { backgroundColor: activePIP ? activeBG : inactiveBG }
							: {}
					}
				>
					{showPIPCopy && screenW < 981 && (
						<div className="copy_dialog">
							<span className="copy_dialog_text">
								{t("components.video_controls.pip_message_1")}{" "}
							</span>
							<span style={{ color: mainColor }}>
								{t("components.video_controls.pip_message_2")}
							</span>
							<div className="right_point"></div>
						</div>
					)}
					<Minireproductor
						onClick={handlePip}
						style={{
							color: activePIP ? mainColor : "#fff",
							fontSize: "25px",
						}}
					/>
				</div>
			)}

			{screenW < 981 && existChat && (
				<div
					className="video-control chat-button"
					onClick={handleToggleChatDialog}
					style={{
						backgroundColor: showChat ? activeBG : inactiveBG,
					}}
				>
					<Message
						style={{
							color: showChat ? mainColor : "#fff",
							fontSize: "25px",
						}}
					/>
				</div>
			)}

			{(screenW < 981 && broadcast.status === "STARTED" && shoppingCart) ||
			(screenW < 981 && isVod && shoppingCart) ? (
				<div
					className="video-control cart-button video-control-cart"
					style={{ backgroundColor: mainColor }}
				>
					<Cart
						onClick={handleToggleCartDialog}
						style={{
							color: getTextColor(mainColor),
							fontSize: "20px",
							opacity: 1,
						}}
					/>
					{cart && cart.length > 0 && (
						<div
							style={{ backgroundColor: getTextColor(mainColor) }}
							className={"cart-indicator"}
						></div>
					)}
				</div>
			) : (
				""
			)}

			{screenW < 981 && broadcast.status === "STARTED" && (
				<div className="video-control heart-container like-button">
					<Heart
						onClick={handleLike}
						style={{
							color: mainColor,
							fontSize: "20px",
							opacity: 1,
						}}
					/>
					<span className="likes-counter">
						{shortNumberFormat(stats.likes)}
					</span>
				</div>
			)}
		</div>
	);
}
