import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
 title?: string;
 titleId?: string;
}

const SvgDelete = ({
                 title,
                 titleId,
                 ...props
                }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    fill="none"
    viewBox="0 0 30 30"
    {...props}
  >
   <g>
    <g>
     <path
       fill="currentColor"
       fillRule="evenodd"
       d="M24.208 7.603c.432 0 .792.36.792.816v.422c0 .446-.36.816-.792.816H5.793C5.36 9.657 5 9.287 5 8.84V8.42c0-.457.36-.816.793-.816h3.24c.658 0 1.23-.467 1.379-1.127l.17-.758C10.844 4.686 11.711 4 12.705 4h4.588c.982 0 1.86.686 2.114 1.663l.181.811c.147.662.72 1.13 1.38 1.13h3.239zm-1.646 15.435c.338-3.152.93-10.641.93-10.717a.828.828 0 00-.2-.62.807.807 0 00-.582-.26H7.298a.785.785 0 00-.581.26.878.878 0 00-.212.62l.059.72c.158 1.958.597 7.415.881 9.997.201 1.902 1.45 3.098 3.257 3.141 1.395.032 2.832.043 4.302.043 1.384 0 2.79-.01 4.228-.043 1.871-.032 3.118-1.207 3.33-3.141z"
       clipRule="evenodd"
     ></path>
    </g>
   </g>
  </svg>
);

export default SvgDelete;
