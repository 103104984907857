import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "./translations/i18n";
import App from "./App";
import { store } from "./store/store";

const container = document.getElementById("root")!;
const root = createRoot(container);
const renderApp = () => {
	root.render(
		<Provider store={store}>
			<App />
		</Provider>
	);
};

renderApp();
