import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgHeart = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 13 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M12.685 2.254a3.697 3.697 0 0 0-.856-1.177A4.004 4.004 0 0 0 10.565.29 4.197 4.197 0 0 0 9.025 0C8.281 0 7.558.192 6.93.554c-.15.087-.294.182-.43.286a3.931 3.931 0 0 0-.428-.286A4.198 4.198 0 0 0 3.977 0c-.534 0-1.052.097-1.54.289-.473.184-.9.45-1.265.788a3.675 3.675 0 0 0-.856 1.177C.106 2.714 0 3.201 0 3.703c0 .473.102.966.305 1.468.17.42.414.854.726 1.293.493.695 1.172 1.42 2.015 2.155a22.432 22.432 0 0 0 2.838 2.094l.356.216a.508.508 0 0 0 .52 0l.356-.216c.059-.036 1.44-.876 2.838-2.094.843-.735 1.522-1.46 2.015-2.155.312-.439.557-.874.726-1.293.203-.502.305-.995.305-1.468a3.448 3.448 0 0 0-.315-1.449Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgHeart;
