import { CredentialsState } from "../utils/types";
import { CredentialsActions } from "./actionsContants";
import { CredentialsActionType } from "./CredentialsActions";

const initialState: CredentialsState = {
	suscriberCredentialsDto: null,
};

export const credentialsReducer = (
	state: CredentialsState = initialState,
	action: CredentialsActionType
): CredentialsState => {
	switch (action.type) {
		case CredentialsActions.updateCredentials: {
			const { payload } = action;
			return { ...state, suscriberCredentialsDto: payload };
		}
		case CredentialsActions.getCredentialsLoading: {
			return { ...state };
		}
		case CredentialsActions.getCredentialsSuccess: {
			const { payload } = action;
			return { ...state, suscriberCredentialsDto: payload };
		}
		default:
			return state;
	}
};
