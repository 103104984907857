import { LayoutActions } from "./actionsContants";

interface UpdateScreenWidthActionType {
	type: LayoutActions.updateScreenWidth;
	payload: number;
}

export const updateScreenWidthActionCreator = (
	unit: number
): UpdateScreenWidthActionType => {
	return { type: LayoutActions.updateScreenWidth, payload: unit };
};

interface UpdateScreenHeightActionType {
	type: LayoutActions.updateScreenHeight;
	payload: number;
}

export const UpdateScreenHeightActionCreator = (
	type: LayoutActions.updateScreenHeight,
	payload: number
): UpdateScreenHeightActionType => {
	return { type, payload };
};

export type LayoutActionType =
	| UpdateScreenWidthActionType
	| UpdateScreenHeightActionType;
