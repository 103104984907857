import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgEye = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 14 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M13.91 5.097A7.424 7.424 0 0 0 7.263.355 7.424 7.424 0 0 0 .618 5.097a.445.445 0 0 0 0 .302 7.424 7.424 0 0 0 6.646 4.742A7.425 7.425 0 0 0 13.909 5.4a.444.444 0 0 0 0-.302ZM7.263 8.14a2.891 2.891 0 1 1 0-5.783 2.891 2.891 0 0 1 0 5.783Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgEye;
