import React, { useRef, useState } from "react";
import AgoraRTM, { RtmChannel, RtmClient } from "agora-rtm-sdk";
import { v4 as uuidv4 } from "uuid";

import { ChatStatus } from "../providers/ChatProvider";
import {
	BanUserResponse,
	Message,
	ModerationMessage,
	PinMessage,
	TextMessage,
	UserMessage,
} from "../types";
import {
	banUserToAPI,
	deleteMessageToAPI,
	pinMessageToAPI,
	sendMessageToAPI,
} from "../services";

const AGORA_APP_ID = process.env.REACT_APP_AGORA_APP_ID;

export enum MessageType {
	NORMAL,
	MODERATION,
	ADMIN,
	PIN,
	DELETE,
}

export enum ModerationReason {
	BAN,
	UNBAN,
}

const createMessage = (
	msg: string,
	from: string,
	type: MessageType = MessageType.NORMAL,
	id?: string,
	time?: number
): TextMessage => {
	const itemId = id === undefined ? uuidv4() : id;
	const timeStamp = time === undefined ? new Date().getTime() : time;
	return {
		itemId,
		type,
		msg,
		from,
		time: timeStamp,
	};
};

const createPinMessage = (
	message: UserMessage,
	isAdmin: boolean
): PinMessage => {
	return { ...message, type: MessageType.PIN, isAdmin };
};

const createDeleteMessage = (message: UserMessage) => {
	return { ...message, type: MessageType.DELETE };
};

const createModMessage = (
	reason: ModerationReason,
	userId: string
): ModerationMessage => {
	return {
		itemId: uuidv4(),
		reason,
		userId,
		type: MessageType.MODERATION,
	};
};

interface UseAgoraSignalingType {
	eventStartDate: string;
	changeChatStatus: (currentStatus: ChatStatus) => void;
	processMessage: (message: UserMessage) => void;
}
export const useAgoraSignaling = ({
	eventStartDate,
	changeChatStatus,
	processMessage,
}: UseAgoraSignalingType) => {
	const [channelId, setChannelId] = useState<string>("");
	const [parentId, setParentId] = React.useState<string>();
	const [currentUser, setCurrentUser] = useState<string>();
	const [bannedUsers, setbannedUsers] = useState<string[]>([]);
	const clientRef = useRef<RtmClient | null>(null);
	const channelRef = useRef<RtmChannel | null>(null);

	const connect = (channelId: string, parentId?: string) => {
		// init client
		clientRef.current = AgoraRTM.createInstance(AGORA_APP_ID || "");
		clientRef.current.on("ConnectionStateChanged", newState => {
			switch (newState) {
				case "CONNECTING":
					changeChatStatus(ChatStatus.connecting);
					break;
				case "CONNECTED":
					changeChatStatus(ChatStatus.connected);
					break;
				case "RECONNECTING":
					changeChatStatus(ChatStatus.reconnecting);
					break;
				case "DISCONNECTED":
					changeChatStatus(ChatStatus.disconnected);
					setCurrentUser(undefined);
					setbannedUsers([]);
					break;
				case "ABORTED":
					changeChatStatus(ChatStatus.disconnected);
					break;
			}
		});

		// init channel
		channelRef.current = parentId
			? clientRef.current.createChannel(parentId)
			: clientRef.current.createChannel(channelId);
		channelRef.current.on("ChannelMessage", (message, memberId) => {
			if (message.text) {
				const parseMessage = JSON.parse(message.text) as Message;
				switch (parseMessage.type) {
					case MessageType.NORMAL:
						if (bannedUsers.find(value => value === memberId)) break;
						processMessage({
							...parseMessage,
							userId: memberId,
						} as UserMessage);
						break;
					case MessageType.ADMIN:
						processMessage({
							...parseMessage,
							userId: memberId,
						} as UserMessage);
						break;
					case MessageType.PIN:
						processMessage({
							...parseMessage,
							userId: memberId,
						} as UserMessage);
						break;
					case MessageType.MODERATION:
						processModMessage(parseMessage as ModerationMessage);
						break;
					case MessageType.DELETE:
						processMessage({
							...parseMessage,
							userId: memberId,
						} as UserMessage);
						break;
				}
			}
		});

		setChannelId(channelId);
		if (parentId) setParentId(parentId);
	};

	const processModMessage = (message: ModerationMessage) => {
		const { userId } = message;
		switch (message.reason) {
			case ModerationReason.BAN:
				setbannedUsers(prevState => [...prevState, userId]);
				break;
			case ModerationReason.UNBAN:
				setbannedUsers(prevState =>
					prevState.filter(value => value !== userId)
				);
				break;
		}
	};

	const login = async (userId: string, token?: string) => {
		if (clientRef.current && channelRef.current) {
			await clientRef.current.login({ uid: userId, token: token });
			await channelRef.current.join();
			setCurrentUser(userId);
		}
	};

	const sendMessage = async (
		msg: string,
		from: string,
		type: MessageType = MessageType.NORMAL
	) => {
		if (channelRef.current) {
			const message = createMessage(msg, from, type);
			if (bannedUsers.find(value => value === currentUser)) {
				processMessage({ ...message, userId: currentUser || "" });
				return true;
			}

			try {
				sendMessageToAPI(
					channelId,
					eventStartDate,
					type,
					{
						...message,
						userId: currentUser || "",
					},
					parentId
				);

				const stringMsg = JSON.stringify(message);
				await channelRef.current.sendMessage({
					text: stringMsg,
					messageType: "TEXT",
				});
				processMessage({ ...message, userId: currentUser || "" });
				return true;
			} catch (error) {
				console.error("MESSAGE ERROR: ", error);
				return false;
			}
		}
		return false;
	};

	const sendPinMessage = async (message: UserMessage) => {
		if (channelRef.current) {
			try {
				const newPinMessage = createPinMessage(
					message,
					message.type === MessageType.ADMIN
				);
				pinMessageToAPI(
					channelId,
					{
						...message,
						userId: currentUser || "",
					},
					parentId
				);

				const stringMsg = JSON.stringify(newPinMessage);
				await channelRef.current.sendMessage({
					text: stringMsg,
					messageType: "TEXT",
				});

				processMessage({ ...newPinMessage, userId: message.userId || "" });
				return true;
			} catch (error) {
				return false;
			}
		}
		return false;
	};

	const sendDeleteMessage = async (message: UserMessage) => {
		if (channelRef.current) {
			try {
				const newDeleteMessage = createDeleteMessage(message);

				deleteMessageToAPI(channelId, newDeleteMessage, parentId);

				const stringMsg = JSON.stringify(newDeleteMessage);
				await channelRef.current.sendMessage({
					text: stringMsg,
					messageType: "TEXT",
				});

				processMessage({ ...newDeleteMessage });
				return true;
			} catch (error) {
				return false;
			}
		}
		return false;
	};

	const processBanSummary = (list: BanUserResponse[]) => {
		setbannedUsers(list.map(item => item.userId));
	};

	const banUser = async (userId: string) => {
		if (channelRef.current) {
			const message = createModMessage(ModerationReason.BAN, userId);
			const stringMsg = JSON.stringify(message);
			try {
				banUserToAPI(channelId, userId, parentId);

				await channelRef.current.sendMessage({
					text: stringMsg,
					messageType: "TEXT",
				});

				setbannedUsers(prevState => [...prevState, userId]);
			} catch (error) {
				console.error("MESSAGE ERROR: ", error);
			}
		}
	};

	const unbanUser = async (userId: string) => {
		// PROCESS UNBANNED USER ON THE BACKEND FIRST

		// -----------
		if (channelRef.current) {
			const message = createModMessage(ModerationReason.UNBAN, userId);
			const stringMsg = JSON.stringify(message);
			try {
				await channelRef.current.sendMessage({
					text: stringMsg,
					messageType: "TEXT",
				});

				setbannedUsers(prevState =>
					prevState.filter(value => value !== userId)
				);
			} catch (error) {
				console.error("MESSAGE ERROR: ", error);
			}
		}
	};

	return {
		connect,
		login,
		sendMessage,
		sendPinMessage,
		sendDeleteMessage,
		processBanSummary,
		banUser,
		unbanUser,
		bannedUsers,
	};
};
