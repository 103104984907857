import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgFacebookLogo = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 62 62"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<circle cx={30.5} cy={30.5} r={30.5} fill="#fff" />
		<path
			d="M61.008 30.69C61.008 13.74 47.35 0 30.508 0 13.656.004 0 13.74 0 30.694c0 15.315 11.155 28.01 25.734 30.314V39.562h-7.739v-8.868h7.747v-6.767c0-7.69 4.556-11.937 11.521-11.937 3.34 0 6.829.599 6.829.599v7.549h-3.847c-3.786 0-4.968 2.367-4.968 4.796v5.757h8.456l-1.35 8.868h-7.11v21.445C49.853 58.7 61.008 46.005 61.008 30.69Z"
			fill="#29439F"
		/>
	</svg>
);

export default SvgFacebookLogo;
