import { Dispatch, SetStateAction, useContext } from "react";
import { DataContext } from "../../context/DataContext";
import { ProductType, RootState } from "../../utils/types";
import currencyFormat from "../../utils/currencyFormat";
import { doClickToProduct, ProductClickType } from "../../utils/actions";
import "./PopupProduct.scss";
import { useDispatch, useSelector } from "react-redux";
import { handleImageLoadError } from "../../utils/handleImageLink";
import noImage from "../../assets/svg/noImage.svg";
import {
	closePopUpActionCreator,
	openPopUpActionCreator,
	ProductClickOrigin,
} from "../../store/PopUpActions";
import { getUser } from "../../utils/chatUserActions";

const urlParams = new URLSearchParams(window.location.search);
const fira_src = urlParams.get("firasrc") || "";

type PopupProductProps = {
	productInfo: ProductType;
	index: number;
	setDisplayPopUp: Dispatch<SetStateAction<boolean>>;
};

export default function PopupProduct({
	productInfo,
	index,
	setDisplayPopUp,
}: PopupProductProps) {
	const { broadcast, queues }: any = useContext(DataContext);
	const { currency } = broadcast;
	const { product } = productInfo;
	const { name, price1, price2, images } = product;
	const screenW = useSelector((state: RootState) => state.layout.screenWidth);
	const existPrice = parseFloat(price1) > 0 || parseFloat(price2) > 0;
	const popup = useSelector((state: RootState) => state.popup);
	const dispatch = useDispatch();

	const labels = product.labels || [];

	const handleClick = () => {
		//Open Pop Up
		if (popup.open) {
			dispatch(closePopUpActionCreator());
			dispatch(
				openPopUpActionCreator(productInfo, ProductClickOrigin.popUpProduct)
			);
		} else {
			dispatch(
				openPopUpActionCreator(productInfo, ProductClickOrigin.popUpProduct)
			);
		}

		const currentUser = getUser(fira_src);
		if (broadcast.status === "STARTED" && currentUser) {
			doClickToProduct(
				currentUser.id,
				productInfo,
				fira_src,
				queues.connectionId,
				broadcast.startDate,
				ProductClickType.popupIntention,
				broadcast.parentId ? broadcast.parentId : undefined
			);
		}

		setDisplayPopUp(true);
	};

	return (
		<div
			className="popup__wrapper"
			onClick={handleClick}
			style={
				screenW < 981
					? {
							marginTop: index === 0 ? "10px" : "8px",
					  }
					: {
							marginTop: "25px",
					  }
			}
		>
			<div className="product__card" style={!existPrice ? { gap: "8px" } : {}}>
				<div className="img__container">
					<img
						src={images && images[0] ? images[0] : noImage}
						alt={name}
						onError={handleImageLoadError}
					/>
				</div>
				<div className="card_text">
					<div className="labels">
						{labels.map((l, index) => (
							<div
								key={index}
								style={{
									backgroundColor: `${
										!l.labelColor ? "red" : `#${l.labelColor}`
									}`,
									color: `${!l.fontColor ? "#fff" : `#${l.fontColor}`}`,
								}}
							>
								{l.name}
							</div>
						))}
					</div>
					<div className="title">{name}</div>
					{existPrice && (
						<div className="prices_wrapper">
							<div className="prices_group">
								{parseFloat(price1) > 0 && (
									<span
										className={`price${
											parseFloat(price2) > 0 ? " oldPrice" : ""
										}`}
									>
										{currencyFormat(parseFloat(price1), currency)}
									</span>
								)}
								{parseFloat(price2) > 0 && (
									<span className={`price discountPrice`}>
										{currencyFormat(parseFloat(price2), currency)}
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

// function dispatch(arg0: any) {
// 	throw new Error("Function not implemented.");
// }
