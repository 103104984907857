import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgVolume = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 23 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M4.907 12.445H1.004a1.005 1.005 0 0 1-1.004-1v-6a.998.998 0 0 1 1.004-1h3.903L10.22.113a.503.503 0 0 1 .82.387v15.89a.5.5 0 0 1-.563.497.502.502 0 0 1-.257-.11l-5.312-4.332h-.001Zm13.566 4.134-1.421-1.416A8.979 8.979 0 0 0 19.203 4.59a8.987 8.987 0 0 0-2.447-3.112l1.426-1.42a10.981 10.981 0 0 1 2.878 3.766 10.943 10.943 0 0 1 1.02 4.622c0 3.223-1.391 6.122-3.607 8.134Zm-3.556-3.543-1.427-1.422a3.996 3.996 0 0 0 1.564-3.169c0-1.43-.752-2.685-1.886-3.392l1.444-1.439a5.973 5.973 0 0 1 2.45 4.831c0 1.842-.833 3.49-2.145 4.591Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgVolume;
