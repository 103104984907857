import stylesheet from "../FiraShoppingCart/FiraShoppingCart.module.scss";
import { Delete, WhatsappFlat } from "../components/FiraIcons";
import { useTranslation } from "react-i18next";
import { useChatContext } from "../FiraChat/providers/ChatProvider";
import { getTextColor } from "../FiraChat/utils/colorUtils";
import ShoppingForm from "./components/ShoppingForm/ShoppingForm";
import { useContext, useEffect, useState } from "react";
import { CartProduct, useCart } from "../context/CartContext";
import currencyFormat from "../utils/currencyFormat";
import { DataContext } from "../context/DataContext";
import noImage from "../assets/svg/noImage.svg";
import Empty from "../components/FiraIcons/Empty";
import { closePip, doPictureInPicture } from "../utils/pipHandler";
import { usePlayerState } from "../context/PlayerStateProvider";
import { Icon } from "@iconify/react";

const {
	ShoppingCartWrapper,
	FiraCartItemWrapper,
	gridImage,
	gridInfo,
	gridActions,
	imageContainer,
	infoTitle,
	deleteIcon,
	price,
	inputWrapper,
	ItemDivider,
	Items,
	Bottom,
	Subtotal,
	subtotalPrice,
	emptyContainer,
	infoText,
	LandingCounter,
	InfoContainer,
	control,
} = stylesheet;

const FiraShoppingCart = () => {
	const { t } = useTranslation();
	const { getChatConfig } = useChatContext();
	const { mainColor } = getChatConfig();
	const [showForm, setShowForm] = useState<boolean>(false);
	const { cart } = useCart();
	const { videoPlayerRef } = usePlayerState();
	const { broadcast }: any = useContext(DataContext);
	const { currency } = broadcast;

	const calculateSumTotal = (products: CartProduct[]): number => {
		let sum = 0;
		for (let i = 0; i < products.length; i++) {
			const item = products[i];
			const price =
				item.price2 && parseFloat(item.price2) > 0
					? parseFloat(item.price2)
					: parseFloat(item.price1);
			sum += price * item.quantity;
		}
		return sum;
	};

	const showCartForm = () => {
		if (videoPlayerRef.current) {
			doPictureInPicture(broadcast.status, videoPlayerRef.current);
		}
		setShowForm(true);
	};

	const closeCartForm = () => {
		closePip();
		setShowForm(false);
	};

	return (
		<>
			<div className={ShoppingCartWrapper}>
				{cart.length > 0 ? (
					<div className={Items}>
						{cart &&
							cart.map((product, i) => <FiraCartItem product={product} />)}
					</div>
				) : (
					<div className={emptyContainer}>
						<Empty />
						<div className={infoText}>
							{t("components.shopping_cart.empty")}
						</div>
					</div>
				)}

				<div className={Bottom}>
					<div className={ItemDivider}></div>
					<div className={Subtotal}>
						<span>Subtotal:</span>{" "}
						<span className={subtotalPrice}>
							{String(currencyFormat(calculateSumTotal(cart), currency))}
						</span>
					</div>
					<button
						onClick={showCartForm}
						style={{
							backgroundColor: mainColor,
							color: getTextColor(mainColor),
						}}
					>
						<WhatsappFlat
							width={24}
							height={24}
							color={getTextColor(mainColor)}
						/>
						<span>{t("components.shopping_cart.buyButtonText")}</span>
					</button>
				</div>
			</div>
			{showForm && <ShoppingForm onClose={closeCartForm} />}
		</>
	);
};

interface FiraCartItemProps {
	product: CartProduct;
}

const FiraCartItem = ({ product }: FiraCartItemProps) => {
	const { getChatConfig } = useChatContext();
	const { mainColor } = getChatConfig();
	const { broadcast }: any = useContext(DataContext);
	const { currency } = broadcast;
	const { updateProductQuantity, removeProductFromCart } = useCart();
	const [qty, setQty] = useState<number>(product.quantity || 1);

	const updateQuantity = (newQty: number) => {
		setQty(newQty);
		updateProductQuantity(product.sku, newQty);
	};

	const deleteProduct = (id: string) => {
		removeProductFromCart(id);
	};

	useEffect(() => {
		setQty(product.quantity);
	}, [product]);

	return (
		<div className={FiraCartItemWrapper}>
			<div id={gridImage}>
				<div className={imageContainer}>
					<img
						src={product.image ? product.image : noImage}
						alt={product.name}
					/>
				</div>
			</div>
			<div id={gridInfo}>
				<div className={infoTitle}>{product.name}</div>
				<div className={deleteIcon}>
					<Delete
						height={24}
						width={24}
						color={"#F16063"}
						onClick={() => deleteProduct(product.sku)}
					/>
				</div>
			</div>
			<div id={stylesheet.extraInfo}>
				<div className={stylesheet.variationText}>{product.variationName}</div>
			</div>
			<div id={gridActions}>
				<div style={{ color: mainColor }} className={price}>
					{String(
						currencyFormat(
							product.price2 && parseFloat(product.price2) > 0
								? parseFloat(product.price2)
								: parseFloat(product.price1),
							currency
						)
					)}
				</div>
				{product.price2 && (
					<div
						style={{ color: mainColor }}
						className={`${price} ${stylesheet.discount}`}
					>
						{String(currencyFormat(parseFloat(product.price1), currency))}
					</div>
				)}
			</div>
			<div id={stylesheet.quantityControl}>
				<div className={inputWrapper}>
					<button onClick={() => updateQuantity(qty - 1)}>
						<Icon icon="ic:round-minus" />
					</button>
					<div>{qty}</div>
					<button onClick={() => updateQuantity(qty + 1)}>
						<Icon icon="ic:round-plus" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default FiraShoppingCart;
