import { v4 as uuid } from "uuid";

// const generateTimestamp = () => {
// 	const currentDate = new Date();
// 	const year = currentDate.getFullYear();
// 	const month = String(currentDate.getMonth() + 1).padStart(2, "0");
// 	const day = String(currentDate.getDate()).padStart(2, "0");
// 	const hour = String(currentDate.getHours()).padStart(2, "0");
// 	const minute = String(currentDate.getMinutes()).padStart(2, "0");
// 	const second = String(currentDate.getSeconds()).padStart(2, "0");
// 	const millisecond = String(currentDate.getMilliseconds()).padStart(3, "0");

// 	const timestamp = `${year}-${month}-${day}-${hour}-${minute}-${second}-${millisecond}`;
// 	return timestamp;
// };

export const generateUID = (): string => {
	return uuid();
};
