import { useState, useContext, useEffect } from "react";
import { DataContext } from "../../context/DataContext";
import "../../styles/helpers.scss";
import "./SharePopUp.scss";
import { sendShare, sendShareVOD } from "../../utils/actions";
import { copyTextToClipboard } from "../../utils/copyTextToClipboard";
import { useTranslation } from "react-i18next";
import {
	CloseButton,
	CopyLogo,
	FacebookLogo,
	GmailLogo,
	LinkedinLogo,
	TelegramLogo,
	WhatsappLogo,
	XLogo,
} from "../FiraIcons";
import { getFontColor } from "../../utils/getFontColor";
import { useSelector } from "react-redux";
import { RootState, VODType, VOD_Data } from "../../utils/types";
import { EventStatus, SocialMedia } from "../../utils/constants";
import { getUser } from "../../utils/chatUserActions";

const urlParams = new URLSearchParams(window.location.search);
const fira_src = urlParams.get("firasrc") || "";

interface SharePopUpProps {
	closePopUp?: () => void;
}

export default function SharePopUp({ closePopUp }: SharePopUpProps) {
	const { t } = useTranslation();
	const [linkCopied, setLinkCopied] = useState(false);
	const { queues, broadcast, shareLink, vod }: any = useContext(DataContext);
	const vodData: VODType = vod?.vod;
	const { firaWebConfiguration } = broadcast;
	const { mainColor } = firaWebConfiguration;
	const [shareText, setShareText] = useState("");
	const screenW = useSelector((state: RootState) => state.layout.screenWidth);

	const countShare = (socialMedia: string) => {
		try {
			if (broadcast && broadcast.status === EventStatus.STARTED) {
				const currentUser = getUser(fira_src);
				if (currentUser) {
					sendShare(
						currentUser.id,
						fira_src,
						queues.connectionId,
						broadcast.startDate,
						broadcast.parentId ? broadcast.parentId : undefined
					);
				}
			} else if (broadcast.status == EventStatus.DONE) {
				const currentTime = document.querySelector("#fira_player")
					? (document.querySelector("#fira_player") as HTMLVideoElement)
							.currentTime
					: 0;
				sendShareVOD(
					shareLink,
					"",
					socialMedia,
					vodData.storeId,
					vod.connectionId,
					vodData.id,
					currentTime
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleShare = async () => {
		await copyTextToClipboard(shareLink);
		setLinkCopied(true);

		if (broadcast && broadcast.status === EventStatus.STARTED) {
			const currentUser = getUser(fira_src);
			if (currentUser) {
				sendShare(
					currentUser.id,
					fira_src,
					queues.connectionId,
					broadcast.startDate,
					broadcast.parentId ? broadcast.parentId : undefined
				);
			}
		} else if (broadcast.status == EventStatus.DONE) {
			const currentTime = document.querySelector("#fira_player")
				? (document.querySelector("#fira_player") as HTMLVideoElement)
						.currentTime
				: 0;
			sendShareVOD(
				shareLink,
				"",
				SocialMedia.URL,
				vodData.storeId,
				vod.connectionId,
				vodData.id,
				currentTime
			);
		}

		setTimeout(() => {
			setLinkCopied(false);
		}, 2000);
	};

	useEffect(() => {
		setShareText(`${t("components.share_message")} ${broadcast.eventName}`);
	}, []);

	return (
		<div id="sharePopUp">
			<div className="share-pop-up_container">
				<div className="share-pop-up_header">
					<div className="share-pop-up_content_title">
						<h5>{t("components.share_popup.title")}</h5>
					</div>
					<CloseButton
						style={{
							color: "#fff",
							fontSize: "16px",
							cursor: "pointer",
						}}
						onClick={closePopUp}
					/>
				</div>
				<div className="share-pop-up_content">
					<div className="share-pop-up_logos">
						{screenW < 981 && (
							<div className="share-pop-up_logo" onClick={handleShare}>
								<CopyLogo
									style={{ fontSize: screenW > 981 ? "61px" : "32px" }}
								/>
								<p className="logo-title">
									{linkCopied
										? t("components.share_popup.copied")
										: t("components.share_popup.copy")}
								</p>
							</div>
						)}
						<a
							className="share-pop-up_logo"
							href={`https://www.facebook.com/sharer/sharer.php?u=${shareLink}&quote=${encodeURIComponent(
								shareText
							)}`}
							target={"_blank"}
							onClick={() => {
								countShare(SocialMedia.FACEBOOK);
							}}
						>
							<FacebookLogo
								style={{
									fontSize: screenW > 981 ? "61px" : "32px",
								}}
							/>
							<p className="logo-title">Facebook</p>
						</a>
						<a
							className="share-pop-up_logo"
							href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
								`${shareText}\n\n${shareLink}`
							)}`}
							target={"_blank"}
							onClick={() => {
								countShare(SocialMedia.WHATSAPP);
							}}
						>
							<WhatsappLogo
								style={{
									fontSize: screenW > 981 ? "61px" : "32px",
								}}
							/>
							<p className="logo-title">Whatsapp</p>
						</a>
						<a
							className="share-pop-up_logo"
							href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
								shareText
							)}&url=${encodeURIComponent(shareLink)}`}
							target={"_blank"}
							onClick={() => {
								countShare(SocialMedia.TWITTER);
							}}
						>
							<XLogo
								style={{
									fontSize: screenW > 981 ? "61px" : "32px",
								}}
							/>
							<p className="logo-title">X</p>
						</a>
						<a
							className="share-pop-up_logo"
							target="_blank"
							href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&body=${
								encodeURIComponent(shareText) + "%0A%0A" + shareLink
							}&subject=${encodeURIComponent(
								shareText
							)}&type=custom_url&app_absent=0`}
							onClick={() => {
								countShare(SocialMedia.GMAIL);
							}}
						>
							<div className="gmail_wrapper">
								<GmailLogo
									style={{
										fontSize: screenW > 981 ? "33px" : "20px",
									}}
								/>
							</div>
							<p className="logo-title">Gmail</p>
						</a>
						<a
							href={`https://t.me/share/url?url=${encodeURIComponent(
								shareLink
							)}&text=${encodeURIComponent(shareText)}`}
							target={"_blank"}
							className="share-pop-up_logo"
							onClick={() => {
								countShare(SocialMedia.TELEGRAM);
							}}
						>
							<TelegramLogo
								style={{
									fontSize: screenW > 981 ? "61px" : "32px",
								}}
							/>
							<p className="logo-title">Telegram</p>
						</a>
						{screenW < 981 && (
							<a
								href={`https://www.linkedin.com/shareArticle?mini=true&url=${shareLink}&title=${encodeURIComponent(
									shareText
								)}&summary=${
									encodeURIComponent(shareText) + "%0A%0A" + shareLink
								}&source=${encodeURIComponent(shareLink)}} `}
								target={"_blank"}
								className="share-pop-up_logo"
								onClick={() => {
									countShare(SocialMedia.LINKEDIN);
								}}
							>
								<LinkedinLogo
									style={{
										fontSize: "32px",
									}}
								/>
								<p className="logo-title">Linkedin</p>
							</a>
						)}
					</div>
					{screenW > 981 && (
						<div className="share-pop-up_link">
							<div className="share-pop-up_link_container">
								<input
									type="text"
									value={shareLink}
									readOnly
									className="share-pop-up_link_input"
								/>
								<button
									className="share-pop-up_link_button"
									onClick={handleShare}
									style={{
										backgroundColor: mainColor,
										color: getFontColor(mainColor),
									}}
								>
									{linkCopied
										? t("components.share_popup.copied")
										: t("components.share_popup.copyLink")}
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
