function hexToRgb(hex: string): [number, number, number] {
	// Remove the hash at the start if it's there
	hex = hex.replace(/^#/, "");

	// Parse r, g, b values
	const bigint = parseInt(hex, 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;

	return [r, g, b];
}

export function getTextColor(hexColor: string): string {
	const [r, g, b] = hexToRgb(hexColor);

	// Normalize RGB values to the [0, 1] range
	const R = r / 255;
	const G = g / 255;
	const B = b / 255;

	// Calculate the perceptive luminance
	const luminance = 0.2126 * R + 0.7152 * G + 0.0722 * B;

	// Bright colors - black font, dark colors - white font
	return luminance > 0.5 ? "#252828" : "#F7F8F9";
}

export function getRgbFromHex(hex: string, opacity: number): string {
	const [r, g, b] = hexToRgb(hex);

	const alpha: number = Math.min(Math.max(opacity, 0), 1);

	return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}
