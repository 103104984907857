import React, { useEffect, useRef } from "react";
import { Icon } from "@iconify/react";

import stylesheet from "./MessageContextMenu.module.scss";
import { useChatContext } from "../../providers/ChatProvider";
import useContextMenu from "../../providers/ContextMenuProvider";
import { ModerationReason } from "../../hooks/useAgoraSignaling";

const { MessageOptionsWrapper, Text, IconWrapper } = stylesheet;

const MessageContextMenu: React.FC = () => {
	const {
		contextMenuState,
		hideContextMenu,
		handleBanUser,
		handlePinMessage,
		handleDeleteMessage,
	} = useContextMenu();
	const { isUserBanned, isMessagePin, currentUser } = useChatContext();
	const menuRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const menuElement = menuRef.current;
		if (menuElement && contextMenuState) {
			const rect = menuElement.getBoundingClientRect();
			const maxX = window.innerWidth - rect.width;
			const maxY = window.innerHeight - rect.height;

			const x = Math.min(maxX, contextMenuState.x);
			const y = Math.min(maxY, contextMenuState.y);

			menuElement.style.left = `${x}px`;
			menuElement.style.top = `${y}px`;
		}
	}, [contextMenuState]);

	useEffect(() => {
		const handleClickOutside = (event: Event) => {
			if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
				hideContextMenu();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [menuRef]);

	if (!contextMenuState) return null;

	const { item } = contextMenuState;

	return (
		<div
			ref={menuRef}
			style={{ position: "fixed", top: 0, left: 0 }}
			className={MessageOptionsWrapper}
		>
			<ul>
				{currentUser && currentUser.userId !== item.userId && (
					<li>
						{isUserBanned(item.userId) ? (
							<button
								onClick={() => {
									handleBanUser(ModerationReason.UNBAN, item.userId);
									hideContextMenu();
								}}
							>
								<span className={Text}>Unban</span>
								<span className={IconWrapper}>
									<Icon icon="material-symbols:block" />
								</span>
							</button>
						) : (
							<button
								onClick={() => {
									handleBanUser(ModerationReason.BAN, item.userId);
									hideContextMenu();
								}}
							>
								<span className={Text}>Ban</span>
								<span className={IconWrapper}>
									<Icon icon="material-symbols:block" />
								</span>
							</button>
						)}
					</li>
				)}

				{!isMessagePin(item.itemId) && (
					<li>
						<button
							onClick={() => {
								handlePinMessage(item);
								hideContextMenu();
							}}
						>
							<span className={Text}>Pin Message</span>
							<span className={IconWrapper}>
								<Icon icon="tabler:pin-filled" />
							</span>
						</button>
					</li>
				)}
				<li>
					<button
						onClick={() => {
							handleDeleteMessage(item);
							hideContextMenu();
						}}
					>
						<span className={Text}>Delete</span>
						<span className={IconWrapper}>
							<Icon icon="solar:trash-bin-2-bold" />
						</span>
					</button>
				</li>
			</ul>
		</div>
	);
};

export default MessageContextMenu;
