import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgSignal = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 42 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M9.121.648c.195.205.35.448.456.716a2.307 2.307 0 0 1 0 1.691c-.106.268-.26.512-.456.717-3.15 3.313-4.92 7.807-4.92 12.493s1.77 9.18 4.92 12.493c.195.205.35.449.455.717a2.308 2.308 0 0 1 0 1.69 2.22 2.22 0 0 1-.456.716 2.095 2.095 0 0 1-.681.479 2.01 2.01 0 0 1-1.607 0 2.096 2.096 0 0 1-.682-.48c-8.2-8.624-8.2-22.608 0-31.232.195-.206.427-.369.682-.48a2.01 2.01 0 0 1 1.608 0c.255.111.486.274.681.48Zm26.729 0c8.2 8.626 8.2 22.608 0 31.232a2.046 2.046 0 0 1-1.477.62 2.05 2.05 0 0 1-1.466-.647c-.39-.41-.61-.963-.615-1.542a2.27 2.27 0 0 1 .589-1.553c3.15-3.313 4.92-7.807 4.92-12.493s-1.77-9.18-4.92-12.493a2.268 2.268 0 0 1-.616-1.562c0-.585.221-1.147.615-1.561A2.049 2.049 0 0 1 34.364 0a2.05 2.05 0 0 1 1.486.647Zm-20.5 6.345c.394.414.615.975.615 1.561 0 .585-.221 1.147-.615 1.56a8.697 8.697 0 0 0-1.784 2.809 9.043 9.043 0 0 0-.627 3.312c0 1.137.213 2.262.627 3.312a8.696 8.696 0 0 0 1.784 2.808c.2.204.36.448.47.717a2.306 2.306 0 0 1 .015 1.707 2.217 2.217 0 0 1-.459.726 2.096 2.096 0 0 1-.69.482 2.01 2.01 0 0 1-1.623-.015 2.1 2.1 0 0 1-.681-.495c-2.331-2.45-3.64-5.775-3.64-9.242 0-3.466 1.309-6.79 3.64-9.242a2.048 2.048 0 0 1 1.484-.646c.557 0 1.09.232 1.484.646Zm14.61 0c2.331 2.451 3.64 5.776 3.64 9.242 0 3.467-1.309 6.791-3.64 9.242a2.097 2.097 0 0 1-.68.48 2.01 2.01 0 0 1-1.608 0 2.096 2.096 0 0 1-.681-.479 2.217 2.217 0 0 1-.456-.716 2.308 2.308 0 0 1 0-1.69 2.22 2.22 0 0 1 .455-.717 8.696 8.696 0 0 0 1.784-2.808 9.043 9.043 0 0 0 .627-3.312 9.043 9.043 0 0 0-.627-3.312 8.697 8.697 0 0 0-1.784-2.808 2.267 2.267 0 0 1-.615-1.562c0-.586.222-1.147.616-1.561a2.049 2.049 0 0 1 1.485-.646 2.05 2.05 0 0 1 1.485.647Zm-8.79 6.116c.414 0 .823.085 1.205.252.382.166.73.41 1.022.718.292.307.524.672.683 1.074a3.463 3.463 0 0 1 0 2.535c-.159.402-.39.767-.683 1.074-.292.308-.64.552-1.022.718a3.014 3.014 0 0 1-1.205.252 3.073 3.073 0 0 1-2.227-.97 3.4 3.4 0 0 1-.922-2.341 3.4 3.4 0 0 1 .922-2.342 3.073 3.073 0 0 1 2.227-.97Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgSignal;
