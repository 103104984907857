import { LayoutState } from "../utils/types";
import { LayoutActions } from "./actionsContants";
import { LayoutActionType } from "./LayoutActions";

const initialState: LayoutState = {
	screenWidth: 0,
	screenHeight: 0,
};

export const layoutReducer = (
	state = initialState,
	action: LayoutActionType
): LayoutState => {
	switch (action.type) {
		case LayoutActions.updateScreenWidth: {
			const { payload } = action;
			return { ...state, screenWidth: payload };
		}
		case LayoutActions.updateScreenHeight: {
			const { payload } = action;
			return { ...state, screenHeight: payload };
		}
		default:
			return state;
	}
};
