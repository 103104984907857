import React, { FC, createContext, useContext, useState } from "react";
interface PlayerContextValue {
	isMuted: boolean;
	setIsMuted: React.Dispatch<React.SetStateAction<boolean>> | null;
}

const PlayerContext = createContext<PlayerContextValue>({
	isMuted: false,
	setIsMuted: null,
});

export const usePlayerContext = () => {
	const context = useContext(PlayerContext);
	if (!context) {
		throw new Error("usePlayerContext must be used within a PlayerProvider");
	}
	return context;
};

interface PlayerProviderProps {
	children: React.ReactNode;
}
export const PlayerProvider: FC<PlayerProviderProps> = ({ children }) => {
	const [isMuted, setIsMuted] = useState(true);

	return (
		<PlayerContext.Provider value={{ isMuted, setIsMuted }}>
			{children}
		</PlayerContext.Provider>
	);
};
