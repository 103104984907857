export const TRANSLATIONS_EN = {
	components: {
		loading_state: "A new transmission will begin soon!",
		header: {
			share: "Share",
		},
		login_user: {
			title: " Login",
			participate: "Participate",
			disclaimer: "By registering your account, you accept our",
			disclaimer_alt: "By registering your account, you accept our",
			disclaimer_1: "data processing policy",
			disclaimer_2: "and the",
			disclaimer_3: "Terms and Conditions of Service.",
			messages: {
				error: "Existing user, please try with other data.",
			},
			form: {
				user: "Enter your name",
			},
			accept: "Accept",
			cancel: "Cancel",
		},
		message_popup: {
			not_started: {
				soon: "It will start soon.",
				hours: "It will start in {{hours}} hours.",
				days: "It will start in {{days}} days.",
			},
			error: {
				title: "Error.",
				message: "Something went wrong, contact support.",
			},
			no_broadcast: {
				title: "Not found.",
				message: "Broadcast not found.",
			},
			no_parameters: {
				title: "Parameters are missing.",
				message: "Validate the broadcast URL.",
			},
			reconnecting: {
				title: "Reconnecting...",
			},
			done: {
				title: "LIVE COMPLETED...",
			},
			done_error: {
				title: "LIVE COMPLETED...",
				message: "There was an error with the video.",
			},
		},
		share_popup: {
			title: "Share link",
			share: "Share",
			copy: "Copy",
			copied: "Copied",
			copyLink: "Copy link",
		},
		video_controls: {
			pip_message_1: "Minimize",
			pip_message_2: "Video",
			copied: "copied",
			link: "Link",
		},
		custom_message_input: {
			input: "Write a message...",
		},
		chat: {
			title: "No chats yet",
			subtitle: "Send a message to start the conversation.",
			scroll_tag: "Scroll to bottom",
			input_placeholder: "Type a message...",
			send_button: "Send",
			error_title: "Update the chat so you can interact.",
			error_button: "Update",
			disconnect_title: "Event finished",
		},
		product_popup: {
			buy: "Buy",
			add_cart: "Add to cart",
			added_cart: "Product Added!",
		},
		share_message: "I invite you to enjoy the live shopping experience at",
		shopping_cart: {
			buyButtonText: "Continue shopping",
			budget: "Send order",
			namePlaceholder: "Enter your name*",
			empty: "You don't have any products",
		},
	},
};
