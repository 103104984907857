import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgCounterBack = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 72 62"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14.321 30.995a25.833 25.833 0 1 1 15.056 23.487 2.584 2.584 0 0 0-2.155 4.692 31 31 0 1 0-18.068-28.18h5.167Z"
			fill="currentColor"
		/>
		<path
			d="M21.895 30.995H1.58a1.292 1.292 0 0 0-.992 2.118l10.158 12.194a1.292 1.292 0 0 0 1.984 0l10.157-12.194a1.292 1.292 0 0 0-.992-2.118Z"
			fill="#fff"
		/>
		<path
			d="M33.517 38.76V23.198l1.354 1.413H30V22h6.606v16.76h-3.09Z"
			fill="currentColor"
		/>
		<path
			d="M45.418 39a12 12 0 0 1-3.445-.503c-1.11-.351-2.044-.83-2.804-1.436l1.307-2.419c.602.495 1.33.894 2.186 1.197a8.182 8.182 0 0 0 2.709.455c1.093 0 1.948-.231 2.566-.694.633-.463.95-1.085.95-1.868 0-.526-.134-.99-.404-1.388-.253-.4-.713-.703-1.378-.91-.65-.208-1.544-.311-2.685-.311h-4.016L41.26 22h9.766v2.61H42.4l1.616-1.485-.642 6.824-1.615-1.46h3.35c1.648 0 2.97.223 3.968.67 1.014.431 1.75 1.038 2.21 1.82.475.766.713 1.644.713 2.634 0 .973-.238 1.867-.713 2.681-.475.815-1.204 1.469-2.186 1.964-.966.495-2.194.742-3.683.742Z"
			fill="#fff"
		/>
	</svg>
);

export default SvgCounterBack;
