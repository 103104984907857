import { PopUpState } from "../utils/types";
import { PopUpActions } from "./actionsContants";
import { PopUpActionType } from "./PopUpActions";

const initialState: PopUpState = {
	open: false,
	product: null,
	from: null,
};

export const popUpReducer = (
	state = initialState,
	action: PopUpActionType
): PopUpState => {
	switch (action.type) {
		case PopUpActions.openPopUp: {
			return { open: true, product: action.payload, from: action.from };
		}
		case PopUpActions.closePopUp: {
			return { open: false, from: null, product: null };
		}
		default:
			return state;
	}
};
