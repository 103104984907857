export enum StatsActions {
	updateLikes = "UPDATE_LIKES_ACTION",
	updateShares = "UPDATE_SHARES_ACTION",
	updateViews = "UPDATE_VIEWS_ACTION",
}

export enum ProductsActions {
	getProductsLoading = "GET_PRODUCTS_LOADING_ACTION",
	getProductsSuccess = "GET_PRODUCTS_SUCCESS_ACTION",
	getProductsFailed = "GET_PRODUCTS_FAILED_ACTION",

	updateProducts = "UPDATE_PRODUCTS_ACTION",
}

export enum LayoutActions {
	updateScreenWidth = "UPDATE_SCREEN_WIDTH_ACTION",
	updateScreenHeight = "UPDATE_SCREEN_HEIGHT_ACTION",
}

export enum CredentialsActions {
	updateCredentials = "UPDATE_CREDENTIALS_ACTION",
	getCredentialsLoading = "GET_CREDENTIALS_LOADING_ACTION",
	getCredentialsSuccess = "GET_CREDENTIALS_SUCCESS_ACTION",
	getCredentialsFailed = "GET_CREDENTIALS_FAILED_ACTION",
}

export enum PopUpActions {
	openPopUp = "OPEN_PRODUCT_POP_UP",
	closePopUp = "CLOSE_PRODUCT_POP_UP",
}
