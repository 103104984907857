import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgWhatsappLogo = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 60 60"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M1.242 29.739a28.59 28.59 0 0 0 3.855 14.368L1 59l15.31-3.996a28.968 28.968 0 0 0 13.8 3.497h.012c15.916 0 28.871-12.893 28.878-28.74.003-7.679-2.998-14.9-8.451-20.332C45.097 3.997 37.846 1.004 30.121 1 14.203 1 1.249 13.892 1.242 29.739"
			fill="url(#WhatsappLogo_svg__a)"
		/>
		<path
			d="M.25 29.73a29.577 29.577 0 0 0 3.988 14.864L0 60l15.837-4.134a29.966 29.966 0 0 0 14.276 3.62h.013c16.464 0 29.867-13.34 29.874-29.732.003-7.945-3.102-15.415-8.742-21.035C45.618 3.1 38.118.003 30.126 0 13.658 0 .257 13.337.25 29.73Zm9.432 14.087-.591-.934a24.565 24.565 0 0 1-3.796-13.151c.005-13.625 11.144-24.71 24.84-24.71 6.633.002 12.866 2.576 17.555 7.246 4.688 4.671 7.268 10.88 7.266 17.484-.006 13.625-11.145 24.712-24.83 24.712h-.01a24.886 24.886 0 0 1-12.638-3.445l-.907-.536-9.398 2.453 2.509-9.119Z"
			fill="url(#WhatsappLogo_svg__b)"
		/>
		<path
			d="M22.627 17.3c-.553-1.236-1.135-1.261-1.66-1.283C20.535 16 20.043 16 19.551 16c-.493 0-1.292.186-1.968.93-.677.743-2.584 2.54-2.584 6.194 0 3.655 2.645 7.187 3.014 7.683.369.495 5.106 8.234 12.608 11.212 6.236 2.474 7.504 1.982 8.858 1.858 1.353-.124 4.367-1.797 4.982-3.531.615-1.734.615-3.221.43-3.532-.184-.31-.676-.495-1.414-.866-.739-.372-4.367-2.169-5.044-2.417-.677-.248-1.169-.371-1.661.373-.492.742-1.906 2.415-2.337 2.91-.43.497-.86.559-1.599.187-.738-.373-3.115-1.156-5.936-3.686-2.194-1.97-3.675-4.4-4.106-5.144-.43-.743-.046-1.145.324-1.516.332-.332.739-.867 1.108-1.3.368-.434.49-.744.737-1.24.246-.495.123-.929-.061-1.3-.185-.372-1.62-4.046-2.276-5.514Z"
			fill="#fff"
		/>
		<defs>
			<linearGradient
				id="WhatsappLogo_svg__a"
				x1={30}
				y1={59}
				x2={30}
				y2={1}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#1FAF38" />
				<stop offset={1} stopColor="#60D669" />
			</linearGradient>
			<linearGradient
				id="WhatsappLogo_svg__b"
				x1={30}
				y1={60}
				x2={30}
				y2={0}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#F9F9F9" />
				<stop offset={1} stopColor="#fff" />
			</linearGradient>
		</defs>
	</svg>
);

export default SvgWhatsappLogo;
