import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgMessage = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 29 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M28.292 11.637c0 6.316-6.2 11.437-13.846 11.437a16.562 16.562 0 0 1-4.062-.5c-1.011.484-3.332 1.412-7.237 2.016-.346.052-.609-.287-.472-.591.613-1.366 1.166-3.186 1.333-4.846C1.888 17.143.6 14.513.6 11.637.6 5.32 6.8.199 14.446.199s13.846 5.12 13.846 11.438Zm-19.039 0c0-.434-.182-.85-.506-1.156a1.784 1.784 0 0 0-1.224-.478c-.46 0-.9.172-1.224.478a1.589 1.589 0 0 0-.507 1.156c0 .433.182.848.507 1.155.324.306.765.479 1.224.479.459 0 .899-.173 1.224-.48.324-.306.506-.721.506-1.154Zm6.924 0a1.59 1.59 0 0 0-.507-1.156 1.784 1.784 0 0 0-1.224-.478c-.46 0-.9.172-1.224.478a1.59 1.59 0 0 0-.507 1.156c0 .433.182.848.507 1.155.325.306.765.479 1.224.479.459 0 .899-.173 1.224-.48.324-.306.507-.721.507-1.154Zm5.192 1.633c.459 0 .9-.172 1.224-.478.324-.306.507-.722.507-1.155a1.59 1.59 0 0 0-.507-1.156 1.784 1.784 0 0 0-1.224-.478c-.46 0-.9.172-1.224.478a1.59 1.59 0 0 0-.507 1.156c0 .433.182.848.507 1.155.325.306.765.479 1.224.479Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgMessage;
