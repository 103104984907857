import React from "react";
import { getAdditionalFields } from "../utils/actions";

interface PlayerState {
	firaKey: string;
	firaSrc: string;
	additionalFields?: {
		[key: string]: string;
	};
	videoPlayerRef: React.MutableRefObject<HTMLVideoElement | null>;
	urlContruct(url: string, fields: { [key: string]: string }): string;
}

const PlayerStateContext = React.createContext<PlayerState | null>(null);

interface ProviderProps {
	firaKey: string;
	firaSrc: string;
	children: React.ReactNode;
}
const PlayerStateProvider: React.FC<ProviderProps> = ({
	firaKey,
	firaSrc,
	children,
}) => {
	const videoPlayerRef = React.useRef<HTMLVideoElement | null>(null);
	const [additionalFields, setAdditionalFields] = React.useState<{
		[key: string]: string;
	}>();

	const urlContruct = (url: string, fields: { [key: string]: string }) => {
		return url.replace(/{{(.*?)}}/g, (match, p1) => fields[p1] || match);
	};

	React.useEffect(() => {
		const loadAdditionalFields = async () => {
			const { data } = await getAdditionalFields(firaKey);
			setAdditionalFields(data);
		};

		loadAdditionalFields();
	}, []);

	return (
		<PlayerStateContext.Provider
			value={{
				firaKey,
				firaSrc,
				additionalFields,
				urlContruct,
				videoPlayerRef,
			}}
		>
			{children}
		</PlayerStateContext.Provider>
	);
};

const usePlayerState = () => {
	const context = React.useContext(PlayerStateContext);
	if (!context) {
		throw new Error(
			"usePlayerState debe ser utilizado dentro de PlayerStateProvider"
		);
	}
	return context;
};

export { usePlayerState, PlayerStateProvider };
