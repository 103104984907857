import { ChatUser } from "../types";

export const addUserToLS = (
	user: ChatUser,
	fira_src: string,
	anonUser: boolean
) => {
	const usersLS = localStorage.getItem("FIRA_USERS") || "[]";
	const users = JSON.parse(usersLS);
	users.push({ user, fira_src, _createdAt: new Date(), anonUser: anonUser });
	localStorage.setItem("FIRA_USERS", JSON.stringify(users));
};

export const updateUserInLS = (
	user: ChatUser,
	fira_src: string,
	anonUser: boolean
) => {
	const usersLS = localStorage.getItem("FIRA_USERS") || "[]";
	const users = JSON.parse(usersLS);
	const userIndex = users.findIndex((u: any) => u.fira_src === fira_src);
	if (userIndex > -1) {
		users[userIndex] = {
			...users[userIndex],
			user: { ...user },
			anonUser,
		};
	}
	localStorage.setItem("FIRA_USERS", JSON.stringify(users));
};

export const getUser = (fira_src: string) => {
	const FIRA_USERS = localStorage.getItem("FIRA_USERS") || "[]";

	if (FIRA_USERS) {
		const users = JSON.parse(FIRA_USERS) as {
			user: ChatUser;
			fira_src: string;
			_createdAt: Date;
			anonUser: boolean;
		}[];
		const user = users.find((u: any) => u.fira_src === fira_src);
		if (user) {
			return { ...user.user, anonUser: user?.anonUser };
		} else {
			return;
		}
	}
};

export const removeUserFromLS = (fira_src: string) => {
	const usersLS = localStorage.getItem("FIRA_USERS") || "[]";
	const users = JSON.parse(usersLS);
	const userIndex = users.findIndex((u: any) => u.fira_src === fira_src);
	if (userIndex > -1) {
		users.splice(userIndex, 1);
	}
	localStorage.setItem("FIRA_USERS", JSON.stringify(users));
};

export const getDaysSinceWeek = () => {
	const now = new Date();
	const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
	const diff = (now.getTime() - weekAgo.getTime()) / (1000 * 60 * 60 * 24);
	return diff;
};

export const addUserConnToLS = (fira_src: string, connectionId: string) => {
	const currentList = localStorage.getItem("FIRA_CONNS") || "[]";
	const parsedList = JSON.parse(currentList) as {
		fira_src: string;
		connectionId: string;
	}[];
	parsedList.push({ fira_src, connectionId });
	localStorage.setItem("FIRA_CONNS", JSON.stringify(parsedList));
};

export const getConn = (fira_src: string) => {
	const currentList = localStorage.getItem("FIRA_CONNS") || "[]";

	if (currentList) {
		const users = JSON.parse(currentList) as {
			fira_src: string;
			connectionId: string;
		}[];
		const conn = users.find(
			(u: { fira_src: string; connectionId: string }) => u.fira_src === fira_src
		);
		if (conn) {
			return conn.connectionId;
		} else {
			return null;
		}
	}
};
