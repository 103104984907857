import React from "react";
import { useTranslation } from "react-i18next";

import stylesheet from "./PlayerLoadingState.module.scss";
import LoadingDots from "../LoadingDots/LoadingDots";

interface PlayerLoadingStateProps {
	color?: string;
	setAbsolute?: boolean;
}
export const PlayerLoadingState: React.FC<PlayerLoadingStateProps> = ({
	color = "#FFDE07",
	setAbsolute = false,
}: PlayerLoadingStateProps) => {
	const { LoadingMessageWrapper } = stylesheet;
	const { t } = useTranslation();
	return (
		<div
			className={LoadingMessageWrapper}
			style={{ position: setAbsolute ? "absolute" : "initial" }}
		>
			<LoadingDots color={color} />
			<p>{t("components.loading_state")}</p>
		</div>
	);
};
