import React, { MutableRefObject, useState } from "react";

import stylesheet from "./VideoProgressBar.module.scss";

const { ProgressBarWrapper, progressBar, BarSeeker, TimerWrapper } = stylesheet;

const formatTime = (timeInSeconds: number): string => {
	const minutes = Math.floor(timeInSeconds / 60);
	const seconds = Math.floor(timeInSeconds % 60);
	return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

interface Props {
	videoRef: MutableRefObject<HTMLVideoElement | null>;
	currentTime: number;
	setCurrentTime: React.Dispatch<React.SetStateAction<number>>;
	color?: string;
}
const VideoProgressBar: React.FC<Props> = ({
	videoRef,
	currentTime,
	setCurrentTime,
	color = "#FFDE07",
}) => {
	const [isSeeking, setIsSeeking] = useState(false);

	const handleSeek = (time: number) => {
		if (videoRef.current) {
			videoRef.current.currentTime = time;
			setCurrentTime(time);
		}
	};

	const handleSeekMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		setIsSeeking(true);
		e.stopPropagation();
	};

	const handleSeekMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
		setIsSeeking(false);
		const seekTime = calculateSeekTime(e);
		handleSeek(seekTime);
		e.stopPropagation();
	};

	const handleSeekMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
		if (isSeeking) {
			const seekTime = calculateSeekTime(e);
			setCurrentTime(seekTime);
			e.stopPropagation();
		}
	};

	const calculateSeekTime = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!videoRef.current) return 0;
		const progressBar = e.currentTarget;
		const rect = progressBar.getBoundingClientRect();
		const seekTime =
			((e.clientX - rect.left) / rect.width) * videoRef.current.duration;
		return seekTime;
	};

	return (
		<div className={ProgressBarWrapper}>
			<div className={TimerWrapper}>
				{formatTime(currentTime)} /{" "}
				{formatTime(videoRef.current?.duration || 0)}
			</div>
			<div
				className={progressBar}
				onMouseDown={handleSeekMouseDown}
				onMouseUp={handleSeekMouseUp}
				onMouseMove={handleSeekMouseMove}
			>
				<div
					className={stylesheet["progress-bar-inner"]}
					style={{
						width: videoRef.current
							? `${(currentTime / videoRef.current?.duration || 0) * 100}%`
							: "0%",
						backgroundColor: color,
					}}
				/>
				<div
					className={BarSeeker}
					style={{
						left: videoRef.current?.duration
							? `${(currentTime / videoRef.current?.duration || 0) * 100}%`
							: "0%",
						backgroundColor: color,
					}}
				/>
			</div>
		</div>
	);
};

export default VideoProgressBar;
