import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgLinkedinLogo = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<mask
			id="LinkedinLogo_svg__a"
			style={{
				maskType: "luminance",
			}}
			maskUnits="userSpaceOnUse"
			x={0}
			y={0}
			width={32}
			height={32}
		>
			<path
				d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Z"
				fill="#fff"
			/>
		</mask>
		<g mask="url(#LinkedinLogo_svg__a)">
			<path
				d="M32.102 29.038c0 1.8-1.453 3.258-3.246 3.258H3.141a3.252 3.252 0 0 1-3.246-3.258V2.964A3.253 3.253 0 0 1 3.14-.294h25.715a3.252 3.252 0 0 1 3.246 3.258v26.074Z"
				fill="#069"
			/>
			<path
				d="M10.455 8.175c0 1.197-.884 2.168-2.338 2.168-1.368 0-2.252-.97-2.252-2.168 0-1.197.913-2.167 2.31-2.167 1.398 0 2.252.94 2.28 2.167ZM5.98 25.992v-13.94h4.332v13.94H5.98ZM12.905 16.5c0-1.739-.056-3.192-.114-4.447h3.764l.2 1.938h.086c.57-.912 1.967-2.251 4.305-2.251 2.851 0 4.989 1.91 4.989 6.015v8.239h-4.333v-7.726c0-1.796-.628-3.021-2.196-3.021-1.197 0-1.909.826-2.224 1.624-.114.285-.142.684-.142 1.082v8.039h-4.333v-9.494l-.002.002Z"
				fill="#fff"
			/>
		</g>
	</svg>
);

export default SvgLinkedinLogo;
