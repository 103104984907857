import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgCopyLogo = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<circle cx={16} cy={16} r={16} fill="#fff" />
		<path
			d="M12.235 25.442a4.405 4.405 0 0 1-4.995-6.428l3.181-5.586a4.405 4.405 0 1 1 7.658 4.36l-.345.604-1.548-.88.347-.61a2.627 2.627 0 0 0-.985-3.575 2.688 2.688 0 0 0-3.58.982l-3.18 5.586a2.623 2.623 0 0 0 .983 3.58 2.689 2.689 0 0 0 3.58-.983l1.548.881a4.379 4.379 0 0 1-2.664 2.069Z"
			fill="#000"
		/>
		<path
			d="M18.633 21.84a4.406 4.406 0 0 1-4.995-6.428l.344-.605 1.548.882-.344.604a2.625 2.625 0 0 0 .983 3.58 2.688 2.688 0 0 0 3.58-.983l3.18-5.587a2.627 2.627 0 0 0-.982-3.578 2.689 2.689 0 0 0-3.58.983l-1.548-.882a4.406 4.406 0 0 1 7.658 4.36l-3.18 5.583a4.383 4.383 0 0 1-2.664 2.07Z"
			fill="#000"
		/>
	</svg>
);

export default SvgCopyLogo;
