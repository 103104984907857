const isAndroid = () => {
	return navigator.userAgent.includes("Android");
};

const isIOS = () => {
	return (
		navigator.userAgent.includes("iPhone") ||
		navigator.userAgent.includes("iPad") ||
		navigator.userAgent.includes("iPod")
	);
};

export const isDesktop = () => {
	return !isAndroid() && !isIOS();
};

export const isMobile = () => {
	return (
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/webOS/i) ||
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/iPad/i) ||
		navigator.userAgent.match(/iPod/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/Windows Phone/i)
	);
};
