import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgArrow = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 8 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M1.236 1.819A1.06 1.06 0 0 1 1.99 0a1 1 0 0 1 .74.313l4.958 4.93a1.065 1.065 0 0 1 0 1.507l-4.959 4.93a1.047 1.047 0 0 1-1.506 0 1.06 1.06 0 0 1 .014-1.506l4.192-4.177-4.192-4.178Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgArrow;
