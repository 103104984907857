import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgCart = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 28 26"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M26.904 6.083a2.727 2.727 0 0 0-2.317-1.363H7.137l-.791-3.081A1.363 1.363 0 0 0 4.982.629H2.256a1.363 1.363 0 1 0 0 2.727h1.69L7.71 17.344a1.363 1.363 0 0 0 1.363 1.008h12.27a1.363 1.363 0 0 0 1.213-.75l4.472-8.943a2.727 2.727 0 0 0-.123-2.576Z"
			fill="currentColor"
		/>
		<path d="M8.39 25.17a2.045 2.045 0 1 0 0-4.09 2.045 2.045 0 0 0 0 4.09ZM22.023 25.17a2.045 2.045 0 1 0 0-4.09 2.045 2.045 0 0 0 0 4.09Z" />
	</svg>
);

export default SvgCart;
