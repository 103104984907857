import React from "react";
import DOMPurify from "dompurify";
import { Icon } from "@iconify/react";

import stylesheet from "./MessageItem.module.scss";
import { formatRelativeTime } from "../../utils/formattersUtils";
import { PinMessage, UserMessage } from "../../types";
import { generateColor } from "../../utils/generateColor";
import { MessageType } from "../../hooks/useAgoraSignaling";
import { ChatModes, useChatContext } from "../../providers/ChatProvider";
import useContextMenu from "../../providers/ContextMenuProvider";

import { getRgbFromHex, getTextColor } from "../../utils/colorUtils";
import { linkify } from "../../utils/linkifyUtils";

const {
	MessageWrapper,
	UserInfoWrapper,
	From,
	Time,
	TextWrapper,
	AdminWrapper,
	OptionsButtonWrapper,
	AdminBadge,
} = stylesheet;

const getMessageTextColor = (
	messageType: MessageType,
	mainColor: string,
	userId = "anon"
): string => {
	switch (messageType) {
		case MessageType.ADMIN:
			return getTextColor(mainColor);
		case MessageType.NORMAL:
			return generateColor(userId);
		case MessageType.PIN:
			return getTextColor(mainColor);
		default:
			return generateColor(userId);
	}
};

const createMarkup = (htmlString: string) => {
	return {
		__html: DOMPurify.sanitize(htmlString, { ADD_ATTR: ["target"] }), // Ensure DOMPurify keeps the target attribute
	};
};

interface Props {
	message: UserMessage | PinMessage;
	msgStyle?: "MESSAGE" | "PIN";
	style?: React.CSSProperties;
}
const MessageItem: React.FC<Props> = ({
	message,
	style,
	msgStyle = "MESSAGE",
}) => {
	const { getChatMode, pinMessage, getChatConfig } = useChatContext();
	const { showContextMenu } = useContextMenu();
	const { userId, type } = message;

	const handleContextMenu = (event: React.MouseEvent, item: UserMessage) => {
		if (msgStyle === "PIN" || getChatMode() === ChatModes.PLAYER) return;
		event.preventDefault();
		showContextMenu(event.clientX, event.clientY, item);
	};

	const { mainColor } = getChatConfig();

	const linkedMessage = linkify(message.msg);

	const isAdminMessage =
		type === MessageType.ADMIN || (message as PinMessage).isAdmin;

	const backgroundColor = isAdminMessage
		? getRgbFromHex(mainColor, 0.4)
		: msgStyle === "PIN"
		? getRgbFromHex("#f7f8f9", 0.4)
		: undefined;

	return (
		<div style={{ ...style, paddingBottom: 8 }}>
			<div
				style={{
					backgroundColor,
				}}
				onContextMenu={event => handleContextMenu(event, message)}
				className={`${MessageWrapper} ${
					type === MessageType.ADMIN ? AdminWrapper : ""
				}`}
			>
				<div className={`${UserInfoWrapper} `}>
					<div
						className={From}
						style={{
							color:
								typeof (message as PinMessage).isAdmin === "boolean" &&
								!(message as PinMessage).isAdmin
									? generateColor(userId)
									: getMessageTextColor(type, mainColor, userId),
						}}
					>
						{message.from}
					</div>
					<div
						style={{
							color:
								type === MessageType.ADMIN
									? getMessageTextColor(type, mainColor, userId)
									: type === MessageType.PIN && (message as PinMessage).isAdmin
									? getMessageTextColor(type, mainColor, userId)
									: "black",
						}}
						className={Time}
					>
						{formatRelativeTime(message.time)}
					</div>

					<div className={OptionsButtonWrapper}>
						{isAdminMessage && (
							<div
								style={{
									color: mainColor,
									backgroundColor: getTextColor(mainColor),
								}}
								className={AdminBadge}
							>
								Admin
							</div>
						)}
						{getChatMode() === ChatModes.ADMIN && msgStyle === "MESSAGE" && (
							<button onClick={event => handleContextMenu(event, message)}>
								<Icon icon="gridicons:ellipsis" />
							</button>
						)}
						{msgStyle === "PIN" && (
							<button
								style={{ color: "#D0D0D0" }}
								onClick={() => pinMessage(message)}
							>
								<Icon icon="tabler:pin-filled" />
							</button>
						)}
					</div>
				</div>
				<div
					style={{
						color:
							type === MessageType.ADMIN
								? getMessageTextColor(type, mainColor, userId)
								: type === MessageType.PIN && (message as PinMessage).isAdmin
								? getMessageTextColor(type, mainColor, userId)
								: "black",
					}}
					className={TextWrapper}
					dangerouslySetInnerHTML={createMarkup(linkedMessage)}
				></div>
			</div>
		</div>
	);
};

export default MessageItem;
