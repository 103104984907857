import { StatsActions } from "./actionsContants";

interface UpdateLikesActionType {
	type: StatsActions.updateLikes;
	payload: number;
}
export const updateLikesActionCreator = (
	unit: number
): UpdateLikesActionType => {
	return { type: StatsActions.updateLikes, payload: unit };
};

interface UpdateSharesActionType {
	type: StatsActions.updateShares;
	payload: number;
}
export const updateSharesActionCreator = (
	unit: number
): UpdateSharesActionType => {
	return { type: StatsActions.updateShares, payload: unit };
};

interface UpdateViewsActionType {
	type: StatsActions.updateViews;
	payload: number;
}
export const updateViewsActionCreator = (
	unit: number
): UpdateViewsActionType => {
	return { type: StatsActions.updateViews, payload: unit };
};

export type StatsActionType =
	| UpdateLikesActionType
	| UpdateSharesActionType
	| UpdateViewsActionType;
