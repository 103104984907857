import FiraChat from "../../FiraChat/FiraChat";
import "./ChatDialog.scss";

export default function ChatDialog() {
	return (
		<div id="ChatDialog">
			<FiraChat />
		</div>
	);
}
