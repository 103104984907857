import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useState,
} from "react";
import { useChatContext } from "./ChatProvider";
import { ModerationReason } from "../hooks/useAgoraSignaling";
import { UserMessage } from "../types";

interface ContextMenuState {
	x: number;
	y: number;
	item: UserMessage;
}

interface ContextMenuContextProps {
	showContextMenu: (x: number, y: number, item: UserMessage) => void;
	hideContextMenu: () => void;
	handlePinMessage: (message: UserMessage) => void;
	handleDeleteMessage: (message: UserMessage) => void;
	handleBanUser: (reason: ModerationReason, userId: string) => void;
	contextMenuState: ContextMenuState | null;
}

const ContextMenuContext = createContext<ContextMenuContextProps | null>(null);

interface ContextMenuProviderProps {
	children: ReactNode;
}

export const ContextMenuProvider: React.FC<ContextMenuProviderProps> = ({
	children,
}) => {
	const [contextMenuState, setContextMenuState] =
		useState<ContextMenuState | null>(null);
	const { pinMessage, deleteMessage, moderateUser } = useChatContext();

	const showContextMenu = useCallback(
		(x: number, y: number, item: UserMessage) => {
			setContextMenuState({ x, y, item });
		},
		[]
	);

	const hideContextMenu = useCallback(() => {
		setContextMenuState(null);
	}, []);

	const handlePinMessage = (message: UserMessage) => pinMessage(message);
	const handleDeleteMessage = (message: UserMessage) => deleteMessage(message);
	const handleBanUser = (reason: ModerationReason, userId: string) =>
		moderateUser(reason, userId);

	return (
		<ContextMenuContext.Provider
			value={{
				showContextMenu,
				hideContextMenu,
				handlePinMessage,
				handleDeleteMessage,
				handleBanUser,
				contextMenuState,
			}}
		>
			{children}
		</ContextMenuContext.Provider>
	);
};

const useContextMenu = () => {
	const context = useContext(ContextMenuContext);
	if (!context)
		throw new Error("useContextMenu must be used within a ContextMenuProvider");
	return context;
};

export default useContextMenu;
