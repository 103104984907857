import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { DataContext } from "../../context/DataContext";
import Product from "../Product/Product";
import { Arrow } from "../FiraIcons/index";
// @ts-ignore
import "glider-js/glider";

import "./productList.scss";
import { useSelector } from "react-redux";
import { ProductType, RootState } from "../../utils/types";
import { getFontColor } from "../../utils/getFontColor";

type ProductListProps = {
	setDisplayPopUp: Dispatch<SetStateAction<boolean>>;
};

export default function ProductList({ setDisplayPopUp }: ProductListProps) {
	const { products, broadcast }: any = useContext(DataContext);
	const { firaWebConfiguration } = broadcast;
	const screenW = useSelector((state: RootState) => state.layout.screenWidth);
	const mainColor = firaWebConfiguration.mainColor || "#beef00";
	const productCarousel: any = useRef(null);
	const [sideToScroll, setSideToScroll] = useState(1);
	const [triesOnScroll, setTriesOnScroll] = useState(0);
	const [productsCarousel, setProductsCarousel] = useState<any>([]);
	const timesToScroll =
		products.length > 0
			? screenW > 981
				? products.length - 4
				: products.length - 1
			: 0;
	const productWidth = 277;
	const CAROUSEL_CONFIG = {
		timeToScroll: 3000,
	};

	const handlePrev = () => {
		const scrollLeft = productCarousel.current?.scrollLeft;
		productCarousel.current?.scrollTo({
			top: 0,
			left: scrollLeft - productWidth,
			behavior: "smooth",
		});
	};

	const handleNext = () => {
		const scrollLeft = productCarousel.current?.scrollLeft;
		productCarousel.current?.scrollTo({
			top: 0,
			left: scrollLeft + productWidth,
			behavior: "smooth",
		});
	};

	const makeScroll = () => {
		const scrollLeft = productCarousel.current?.scrollLeft;
		productCarousel.current?.scrollTo({
			top: 0,
			left:
				sideToScroll === 1
					? scrollLeft + productWidth
					: scrollLeft - productWidth,
			behavior: "smooth",
		});
		setTriesOnScroll(triesOnScroll + 1);
	};

	useEffect(() => {
		// @ts-ignore
		new Glider(document.querySelector(".glider"), {
			// `auto` allows automatic responsive
			draggable: false,
			// how much to scroll with each mouse delta
			dragVelocity: 3.3,
			// use any custom easing function
			// compatible with most easing plugins
			easing: function (x: any, t: any, b: any, c: any, d: any) {
				return c * (t /= d) * t + b;
			},
			// event control
			scrollPropagate: false,
			eventPropagate: true,
			// Force centering slide after scroll event
			scrollLock: false,
			// how long to wait after scroll event before locking
			// if too low, it might interrupt normal scrolling
			scrollLockDelay: 150,
			// Force centering slide after resize event
			resizeLock: true,
		});
	}, []);

	useEffect(() => {
		const productsOnPopup = products.filter(
			(product: ProductType) => product.onPopup
		);
		const productsNotOnPopup = products.filter(
			(product: ProductType) => !product.onPopup
		);

		setProductsCarousel([...productsOnPopup, ...productsNotOnPopup]);

	}, [products]);

	return (
		<div
			className="products_wrapper"
			style={
				products.length == 0
					? {
							display: "none",
					  }
					: {}
			}
		>
			<div
				className="prevArrow"
				style={{
					backgroundColor: mainColor,
				}}
				onClick={handlePrev}
			>
				<Arrow
					style={{
						color: getFontColor(mainColor),
					}}
				/>
			</div>
			<div className="glider" id="products" ref={productCarousel}>
				{productsCarousel.map((productInfo: any, index: number) => {
					return (
						<Product
							key={index}
							productInfo={productInfo}
							setDisplayPopUp={setDisplayPopUp}
						/>
					);
				})}
			</div>
			<div
				className="nextArrow"
				style={{
					backgroundColor: mainColor,
				}}
				onClick={handleNext}
			>
				<Arrow
					style={{
						color: getFontColor(mainColor),
					}}
				/>
			</div>
		</div>
	);
}
