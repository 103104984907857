import { ProductType } from "../utils/types";
import { PopUpActions } from "./actionsContants";

export enum ProductClickOrigin {
	regularProduct = "REGULAR_PRODUCT",
	popUpProduct = "POP_UP_PRODUCT",
}
interface OpenPopUpActionType {
	type: PopUpActions.openPopUp;
	payload: ProductType;
	from: ProductClickOrigin;
}

export const openPopUpActionCreator = (
	display: ProductType,
	typeOfProduct: ProductClickOrigin
): OpenPopUpActionType => {
	return {
		type: PopUpActions.openPopUp,
		payload: display,
		from: typeOfProduct,
	};
};

interface ClosePopUpActionType {
	type: PopUpActions.closePopUp;
}

export const closePopUpActionCreator = (): ClosePopUpActionType => {
	return { type: PopUpActions.closePopUp };
};

export type PopUpActionType = OpenPopUpActionType | ClosePopUpActionType;
