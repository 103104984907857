import styles from "./BottomSheetFiraShoppingCart.module.scss";
import FiraShoppingCart from "../../FiraShoppingCart/FiraShoppingCart";
import { CloseButtonX } from "../FiraIcons";
import React from "react";
import { useTranslation } from "react-i18next";

const BottomSheetFiraShoppingCart = () => {
	const { t } = useTranslation();

	const handleToggleCartDialog = () => {
		const chatDialog = document.querySelector("#CartDialog") as HTMLElement;
		if (chatDialog.style.display == "block") {
			chatDialog.style.display = "none";
		} else {
			chatDialog.style.display = "block";
		}
	};

	return (
		<div
			id="CartDialog"
			className={`${styles.BottomSheetWrapper} ${styles.SheetOpen}`}
		>
			<div className={`${styles.Top}`}>
				<span className={`${styles.Title}`}>Carrito de compras</span>
				<CloseButtonX
					color={"#D9D9D9"}
					style={{ cursor: "pointer" }}
					onClick={handleToggleCartDialog}
				/>
			</div>
			<FiraShoppingCart />
		</div>
	);
};

export default BottomSheetFiraShoppingCart;
