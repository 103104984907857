export const getFontColor = (hex = "#D10A3D") => {
	if (hex.slice(0, 1) === "#") {
		hex = hex.slice(1);
	}

	if (hex.length === 3) {
		hex = hex
			.split("")
			.map(function (hex) {
				return hex + hex;
			})
			.join("");
	}

	const r = parseInt(hex.substr(0, 2), 16);
	const g = parseInt(hex.substr(2, 2), 16);
	const b = parseInt(hex.substr(4, 2), 16);

	const yiq = (r * 299 + g * 587 + b * 114) / 1000;

	return yiq >= 128 ? "#010202" : "#ffffff";
};
