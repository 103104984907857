import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
 title?: string;
 titleId?: string;
}

const SvgWhatsappFlat = ({
                    title,
                    titleId,
                    ...props
                   }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
    {
   ...props
    }
  >
   <path
     fill="currentColor"
     d="M12.945 3.239c-2.09-3.24-6.37-4.2-9.68-2.19-3.23 2.01-4.27 6.39-2.18 9.62l.17.26-.7 2.62 2.62-.7.26.17c1.13.61 2.36.96 3.58.96 1.31 0 2.62-.35 3.75-1.05 3.23-2.1 4.19-6.39 2.18-9.71v.02zm-1.83 6.74c-.35.52-.79.87-1.4.96-.35 0-.79.17-2.53-.52-1.48-.7-2.71-1.84-3.58-3.15-.52-.61-.79-1.4-.87-2.19 0-.7.26-1.31.7-1.75.17-.17.35-.26.52-.26h.44c.17 0 .35 0 .44.35.17.44.61 1.49.61 1.58.09.09.05.76-.35 1.14-.22.25-.26.26-.17.44.35.52.79 1.05 1.22 1.49.52.44 1.05.79 1.66 1.05.17.09.35.09.44-.09.09-.17.52-.61.7-.79.17-.17.26-.17.44-.09l1.4.7c.17.09.35.17.44.26.09.26.09.61-.09.87h-.02z"
   ></path>
  </svg>
);

export default SvgWhatsappFlat;
