export function linkify(inputText: string): string {
	// Regex to match a basic URL pattern
	// Note: Removed unnecessary escape sequences after double checking the pattern
	const urlRegex =
		/(\b(https?:\/\/)?[a-z0-9-]+(\.[a-z0-9-]+)+([-A-Z0-9+&@#/%=~_|!:,.;]*)*\/?\b)/gi;
	return inputText.replace(urlRegex, url => {
		// Check if the URL starts with a protocol. If not, prepend 'http://'.
		const protocolPattern = /^https?:\/\//i;
		let properUrl = protocolPattern.test(url) ? url : `http://${url}`;
		// Check if a URL does not contain protocol or 'www' and is just a domain and possibly a path
		if (!protocolPattern.test(url) && !url.startsWith("www.")) {
			properUrl = `http://www.${url}`; // Prepend 'http://www.' to plain domain
		}
		return `<a href="${properUrl}" target="_blank" rel="noopener noreferrer">${url}</a>`;
	});
}
