import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgShare = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 9 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M1.656 5.838a1.69 1.69 0 0 0 1.114-.417l2.957 1.606c-.1.368-.056.758.123 1.098.178.34.48.607.848.754.37.146.781.16 1.16.041.38-.119.702-.364.907-.69.205-.326.28-.712.21-1.086a1.567 1.567 0 0 0-.589-.951c-.31-.24-.7-.366-1.1-.354-.4.012-.78.16-1.075.417L3.254 4.65a1.42 1.42 0 0 0 .051-.345L6.21 2.726c.28.242.639.385 1.016.405a1.71 1.71 0 0 0 1.058-.293c.306-.21.528-.515.626-.862A1.496 1.496 0 0 0 8.826.93a1.604 1.604 0 0 0-.758-.76A1.724 1.724 0 0 0 6.975.035c-.37.075-.701.268-.939.548a1.52 1.52 0 0 0-.365.989c.002.13.02.258.056.383L3.042 3.413a1.626 1.626 0 0 0-.626-.541 1.724 1.724 0 0 0-1.635.06 1.608 1.608 0 0 0-.58.584 1.51 1.51 0 0 0 .032 1.555c.147.235.355.428.605.563.25.134.531.205.818.204Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgShare;
