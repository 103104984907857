let savedSelection: Range | null = null;

export const saveCaretPosition = (context: HTMLElement) => {
  const selection = window.getSelection();
  if (selection && selection.rangeCount > 0) {
    savedSelection = selection.getRangeAt(0);
  }
};

export const restoreCaretPosition = (context: HTMLElement) => {
  if (savedSelection) {
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(savedSelection);
    }
  }
};