import { Dispatch, SetStateAction, useContext, useEffect, useRef } from "react";
import Hls from "hls.js";
import { optionsType, RecordingType } from "../../utils/types";
import { findVideo } from "../../utils/actions";
import { DataContext } from "../../context/DataContext";

type VideoJSProps = {
	options: optionsType;
	playerRef: any;
	setIsVideoPaused: Dispatch<SetStateAction<boolean>>;
	interval: NodeJS.Timer | undefined;
	setUrlRecording: Dispatch<SetStateAction<string>>;
	setvideoExist: Dispatch<SetStateAction<boolean>>;
	setVideoHasError: Dispatch<SetStateAction<boolean>>;
	listUrlsRecording: RecordingType[];
	handleOnEnded: () => void;
	handlePopup: () => void;
	handleTimeUpdate: () => void;
	isMuted: boolean;
};

export default function VideoJS({
	options,
	playerRef,
	setIsVideoPaused,
	interval,
	setUrlRecording,
	setvideoExist,
	setVideoHasError,
	listUrlsRecording,
	handleOnEnded,
	handlePopup,
	handleTimeUpdate,
	isMuted,
}: VideoJSProps) {
	const playerWrapperRef: any = useRef();
	const { broadcast }: any = useContext(DataContext);

	// Dispose the Video.js player when the functional component unmounts
	useEffect(() => {
		if (playerWrapperRef.current && options && options.src !== "") {
			if (options.type == "application/x-mpegURL") {
				const video = document.getElementById(
					"fira_player"
				) as HTMLVideoElement;
				if (Hls.isSupported()) {
					const hls = new Hls();
					hls.loadSource(options.src);
					hls.attachMedia(video);
				} else {
					video.src = options.src;
				}

				playerRef.current = document.querySelector(
					"#player_media_wrapper video"
				);
			} else if (options.type == "video/mp4") {
				playerRef.current = document.querySelector(
					"#player_media_wrapper video"
				);

				if (playerRef.current) {
					playerRef.current.src = options.src;
					playerRef.current.load();
					setIsVideoPaused(true);
				}
			}
		}
	}, [playerWrapperRef, options, playerRef]);

	useEffect(() => {
		if (playerRef.current) {
			playerRef.current.addEventListener("leavepictureinpicture", () => {
				handlePopup();
			});

			playerRef.current.addEventListener("error", () => {
				if (broadcast.status === "DONE") {
					const typeRecording = options.src
						.split(".")
						.pop()
						?.toLocaleLowerCase();
					if (typeRecording === "mp4") {
						const hlsVideoUrl = findVideo(listUrlsRecording, "HLS");
						if (hlsVideoUrl) {
							setUrlRecording(hlsVideoUrl.url);
							setvideoExist(true);
							setVideoHasError(false);

							if (interval) clearInterval(interval);
						}
						return;
					}
				}

				setUrlRecording("");
				setvideoExist(false);
				setVideoHasError(true);
			});

			if (!playerRef.current.paused) playerRef.current.pause();
			setIsVideoPaused(true);
		}
		return () => {
			playerRef.current.removeEventListener("leavepictureinpicture", () => {
				handlePopup();
			});
		};
	}, [playerRef]);

	return (
		<div id="player_media_wrapper" ref={playerWrapperRef}>
			<video
				id="fira_player"
				playsInline
				ref={playerRef}
				onEnded={handleOnEnded}
				onTimeUpdate={handleTimeUpdate}
				muted={isMuted}
			/>
		</div>
	);
}
