import React from "react";

const socketURL =
	process.env.REACT_APP_BASE_URL_CONNECTION ||
	"wss://fira-connection-ms-dev.azurewebsites.net";

const socketApiKey = process.env.REACT_APP_SOCKET_CONNECTION_API_KEY || "";

export enum SocketEvents {
	LIKE = "like",
	CONN = "conn",
	NEW_PRODUCT = "newProducts",
	REMOVE_PRODUCT = "removeProducts",
	ACTIVE_PRODUCT = "activateProduct",
	ADD_POPUP = "addPopup",
	REMOVE_POPUP = "removePopup",
	CLOSE_PLAYER = "closingPlayer",
	START_PLAYER = "startingPlayer",
}

const maxReconnectAttemp = 10;

const useSocket = (onMessageHandler: (event: MessageEvent) => void) => {
	const socketRef = React.useRef<WebSocket | null>(null);
	const socketUrlRef = React.useRef<URL | null>(null);
	const [connectAttemps, setConnectAttemps] = React.useState(0);

	const makeNewConnection = (broadcastingId: string, connectionId: string) => {
		const socketUrl = new URL("/websocket", socketURL);
		socketUrl.searchParams.set("broadcastingId", broadcastingId);
		socketUrl.searchParams.set("connectionId", connectionId);
		socketUrl.searchParams.set("value", socketApiKey);
		socketUrlRef.current = socketUrl;
		return new WebSocket(socketUrl);
	};

	const startConnection = (connectionId: string, broadcastingId: string) => {
		const newConnection = makeNewConnection(broadcastingId, connectionId);
		newConnection.onmessage = onMessageHandler;
		newConnection.onerror = handleConnectionError;
		socketRef.current = newConnection;
	};

	const startReconnection = () => {
		if (socketRef.current) {
			socketRef.current.close();
		}
		if (socketUrlRef.current) {
			const newConnection = new WebSocket(socketUrlRef.current);
			newConnection.onmessage = onMessageHandler;
			newConnection.onerror = handleConnectionError;
			socketRef.current = newConnection;
		}

		setConnectAttemps(connectAttemps + 1);
	};

	const handleConnectionError = (event: Event) => {
		if (connectAttemps < maxReconnectAttemp) {
			setTimeout(startReconnection, 5000);
		}
	};

	const cleanUp = () => {
		if (socketRef.current) {
			socketRef.current.close();
		}
	};

	return { startConnection, cleanUp };
};

export default useSocket;
