import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgCloseButton = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M15 3a1.414 1.414 0 0 0-2-2L1.001 13a1.414 1.414 0 1 0 2 2L15 3Z"
			fill="#fff"
		/>
		<path
			d="M1 3a1.414 1.414 0 1 1 2-2l11.999 12a1.414 1.414 0 1 1-2 2L1 3Z"
			fill="#fff"
		/>
	</svg>
);

export default SvgCloseButton;
