import { useContext, useEffect, useRef } from "react";
import { DataContext } from "../../context/DataContext";
import { Eye } from "../FiraIcons";
import "./header.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/types";

type HeaderType = {
	showControls: boolean;
};

export default function Header({ showControls }: HeaderType) {
	const { broadcast }: any = useContext(DataContext);
	const stats = useSelector((state: RootState) => state.stats);
	const detailsRef = useRef<HTMLDivElement>(null);
	const liveTagRef = useRef<HTMLDivElement>(null);
	const existLogo =
		broadcast && broadcast.storeLogo && broadcast.storeLogo !== "";

	useEffect(() => {
		if (liveTagRef && liveTagRef.current && detailsRef && detailsRef.current) {
			if (showControls) {
				liveTagRef.current.style.visibility = "hidden";
				liveTagRef.current.style.opacity = "0";
				detailsRef.current.style.visibility = "visible";
				detailsRef.current.style.opacity = "1";
			} else {
				liveTagRef.current.style.visibility = "visible";
				liveTagRef.current.style.opacity = "1";
				detailsRef.current.style.visibility = "hidden";
				detailsRef.current.style.opacity = "0";
			}
		}
	}, [showControls]);

	return (
		<div id="header">
			<div
				className="info_container details_container"
				ref={detailsRef}
				style={{
					gridTemplateColumns: existLogo ? "repeat(2, max-content)" : "1fr",
					visibility: "hidden",
					opacity: 0,
					transition: "visibility 0.5s, opacity 0.5s",
				}}
			>
				{existLogo && (
					<div className="image__container">
						<div
							className={`image__circle ${
								broadcast && broadcast.status === "STARTED" ? "live" : ""
							}`}
						>
							<img
								className="image__logo"
								src={broadcast.storeLogo}
								alt="fira-logo"
							/>
						</div>
						{broadcast && broadcast.status === "STARTED" && (
							<div className="live__icon">
								<span className="live-badge">LIVE</span>
							</div>
						)}
					</div>
				)}

				<div className="title_wrapper">
					{broadcast && broadcast.storeName && (
						<p className="header_title">
							{broadcast.storeName} - {broadcast.eventName}
						</p>
					)}

					<div className="fira_live__container">
						<span className="text-white">FIRA ON</span>
						<span className="text-yellow">LIVE</span>
					</div>
				</div>
			</div>

			<div
				className="info_container live_tag_container"
				ref={liveTagRef}
				style={{
					transition: "visibility 0.3s, opacity 0.3s",
					visibility: "visible",
				}}
			>
				{broadcast.status === "STARTED" && (
					<div className="fira_live__container live_tag">
						<span className="text-white">LIVE</span>
					</div>
				)}

				<div className="fira_live__container">
					<span className="text-white">FIRA ON</span>
					<span className="text-yellow">LIVE</span>
				</div>
			</div>
			<div className="stats_container">
				{broadcast.status === "STARTED" && (
					<div className="stats_element">
						<Eye
							style={{
								fontSize: "13px",
								color: "#fff",
							}}
						/>
						<span className="stats-text">{stats.views}</span>
					</div>
				)}
			</div>
		</div>
	);
}
