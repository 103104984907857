import {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import { DataContext } from "../../context/DataContext";
import { useSelector } from "react-redux";
import { ProductType, RootState } from "../../utils/types";
import { Eye } from "../FiraIcons";
import PopupProduct from "../PopupProduct/PopupProduct";
import "./PlayerTop.scss";
import Header from "../Header/Header";

type PlayerTopType = {
	showControls: boolean;
	setDisplayPopUp: Dispatch<SetStateAction<boolean>>;
};

export default function PlayerTop({
	showControls,
	setDisplayPopUp,
}: PlayerTopType) {
	const { broadcast, products }: any = useContext(DataContext);
	const screenW = useSelector((state: RootState) => state.layout.screenWidth);
	const views = useSelector((state: RootState) => state.stats.views);

	const productsOnPopup = products.filter(
		(product: ProductType) => product.onPopup
	);

	return (
		<div id="video_top">
			{screenW > 981 && <Header showControls={showControls} />}
			{screenW < 981 && (
				<div className="image__container">
					{broadcast && broadcast.storeLogo && (
						<div
							className={`image__circle ${
								broadcast && broadcast.status === "STARTED" ? "live" : ""
							}`}
						>
							<img
								className="image__logo"
								src={broadcast.storeLogo}
								alt="fira-logo"
							/>
						</div>
					)}
					{broadcast && broadcast.status === "STARTED" && (
						<div className="live__icon">
							<span className="live-badge">LIVE</span>
						</div>
					)}
				</div>
			)}
			{screenW < 981 && (
				<div className="fira_live__container">
					<span className="text-white">FIRA ON</span>
					<span className="text-yellow">LIVE</span>
				</div>
			)}
			{screenW < 981 && broadcast.status === "STARTED" && (
				<div className="views__container">
					<Eye style={{ fontSize: "15px", color: "#fff" }} />
					<span>{views}</span>
				</div>
			)}

			{productsOnPopup.map((popup: any, i: number) => {
				return (
					<PopupProduct
						key={popup.id}
						productInfo={popup}
						index={i}
						setDisplayPopUp={setDisplayPopUp}
					/>
				);
			})}
		</div>
	);
}
