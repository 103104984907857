export function formatNumber(num: number): string {
	if (num < 1000) {
		// If the number is less than 1000, return it as a string
		return num.toString();
	} else {
		// If the number is 1000 or greater, create a shorter version
		const suffixes = ["K", "M", "B", "T"];
		let suffixIndex = 0;

		while (num >= 1000 && suffixIndex < suffixes.length - 1) {
			suffixIndex++;
			num /= 1000;
		}

		// Round the number to remove any decimals
		num = Math.round(num);
		return `${num}${suffixes[suffixIndex]}`;
	}
}
