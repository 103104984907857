import { CartProduct } from "../context/CartContext";

const FIRA_CART_KEY = "FIRA_CART";

interface CartHistory {
	src: string;
	cart: CartProduct[];
}

export const initCart = (currentSrc: string): CartProduct[] => {
	const storedCart = localStorage.getItem(FIRA_CART_KEY);
	if (storedCart) {
		const cartHistory = JSON.parse(storedCart) as CartHistory[];
		const current = cartHistory.find(h => h.src === currentSrc);
		if (current) return current.cart;
	}

	return [];
};

export const saveCart = (currentSrc: string, cart: CartProduct[]) => {
	const storedCart = localStorage.getItem(FIRA_CART_KEY);
	let cartHistory: CartHistory[] = [];

	if (storedCart) {
		try {
			cartHistory = JSON.parse(storedCart) as CartHistory[];
		} catch (error) {
			console.error("Failed to parse stored cart history:", error);
			cartHistory = [];
		}

		const existingCartIndex = cartHistory.findIndex(h => h.src === currentSrc);
		if (existingCartIndex !== -1) {
			cartHistory[existingCartIndex].cart = cart;
		} else {
			cartHistory.push({ src: currentSrc, cart });
		}
	} else {
		cartHistory = [{ src: currentSrc, cart }];
	}

	try {
		localStorage.setItem(FIRA_CART_KEY, JSON.stringify(cartHistory));
	} catch (error) {
		console.error("Failed to save cart history to localStorage:", error);
	}
};
