import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgMinireproductor = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 24 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M4.12 0A4.12 4.12 0 0 0 0 4.12v8.243a4.12 4.12 0 0 0 4.12 4.12h5.77v-3.296a3.296 3.296 0 0 1 3.297-3.297h8.242V4.12A4.12 4.12 0 0 0 17.308 0H4.12Zm.242 4.362a.824.824 0 0 1 1.167 0l2.713 2.714V5.77a.824.824 0 1 1 1.648 0v3.297a.824.824 0 0 1-.824.824H5.769a.824.824 0 1 1 0-1.648h1.307L4.362 5.529a.824.824 0 0 1 0-1.167Zm7.177 8.825a1.648 1.648 0 0 1 1.648-1.648h8.242a1.648 1.648 0 0 1 1.648 1.648v4.945a1.648 1.648 0 0 1-1.648 1.648h-8.242a1.648 1.648 0 0 1-1.648-1.648v-4.945Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgMinireproductor;
