import React, { PropsWithChildren } from "react";

import stylesheet from "./MobileSheetWrapper.module.scss";

const { SheetWrapper } = stylesheet;

const MobileSheetWrapper = ({ children }: PropsWithChildren) => {
	return <div className={SheetWrapper}>{children}</div>;
};

export default MobileSheetWrapper;
