import {
	RSocketClient,
	JsonSerializer,
	IdentitySerializer,
} from "rsocket-core";
import RSocketWebSocketClient from "rsocket-websocket-client";
import { getBrowserName } from "../utils/getBrowserName";
import { isDesktop } from "../utils/isDesktop";

const brokerUrl = process.env.REACT_APP_BASE_URL_BROKER || "";

const transport = new RSocketWebSocketClient({
	url: `${brokerUrl}/connection/video`,
});

const client = new RSocketClient({
	transport,
	serializers: {
		data: JsonSerializer,
		metadata: IdentitySerializer,
	},
	setup: {
		keepAlive: 60000,
		lifetime: 180000,
		dataMimeType: "application/json",
		metadataMimeType: "message/x.rsocket.routing.v0",
	},
});

export const connectToRSocket = (
	geodata: any,
	storeId: string,
	videoId: string,
	handleConnected: (data: any) => void
) => {
	console.log("Conectando al servidor de RSocket");

	client.connect().subscribe({
		onComplete: socket => {
			// Creando la solicitud de suscripción
			const request = {
				data: {
					videoId,
					storeId,
					connectionType: isDesktop() ? "WEB" : "MOBILE",
					agent: getBrowserName().name,
					clientAgentAddr: {
						ip: geodata || "not_found",
						city: "city",
						country: "country_code",
						continent: "continent_code",
						zipCode: "postal",
						timeZone: "",
					},
				},
				metadata: String.fromCharCode("new".length) + "new",
			};
			socket.requestResponse(request).subscribe({
				onComplete: payload => {
					if (payload.data.code == "BAD_REQUEST") {
						console.log(
							`Hubo un error realizando la conexion: ${payload.data.code}: ${payload.data.message}`
						);
					} else {
						handleConnected(payload.data);
						console.log("Conexión realizada con éxito");
					}
				},
				onError: error => {
					console.error("Error enviando solicitud", error);
				},
			});
		},
		onError: error => {
			console.error("Error conectando al servidor de RSocket", error);
		},
	});
};
