export const TRANSLATIONS_ES = {
	components: {
		loading_state: "¡Pronto empezará una nueva transmisión!",
		header: {
			share: "Compartir",
		},
		login_user: {
			title: "Ingresar",
			participate: "Participar",
			disclaimer: "Al registrar su cuenta, acepta nuestra",
			disclaimer_alt: "Al registrar su cuenta, acepta nuestros",
			disclaimer_1: "politica de tratamiento de datos",
			disclaimer_2: "y los",
			disclaimer_3: "Términos y condiciones del servicio.",
			messages: {
				error: "Usuario existente, por favor intente con otros datos.",
			},
			form: {
				user: "Ingresa tu nombre",
			},
			accept: "Acceder",
			cancel: "Cancelar",
		},
		message_popup: {
			not_started: {
				soon: "Comenzará pronto.",
				hours: "Comenzará en {{hours}} horas.",
				days: "Comenzará en {{days}} dias.",
			},
			error: {
				title: "Error.",
				message: "Algo salió mal, contacta a soporte.",
			},
			no_broadcast: {
				title: "No encontrado.",
				message: "No se encontró el broadcast.",
			},
			no_parameters: {
				title: "Faltan parámetros.",
				message: "Valide la URL del broadcast.",
			},
			reconnecting: {
				title: "Reconectando...",
			},
			done: {
				title: "LIVE FINALIZADO...",
			},
			done_error: {
				title: "LIVE FINALIZADO...",
				message: "Hubo un error con el video.",
			},
		},
		share_popup: {
			title: "Compartir enlace",
			share: "Compartir",
			copy: "Copiar",
			copyLink: "Copiar enlace",
			copied: "Copiado",
		},
		video_controls: {
			pip_message_1: "Minimizar",
			pip_message_2: "Video",
			link: "Link",
			copied: "copiado",
		},
		custom_message_input: {
			input: "Escribe un mensaje...",
		},
		chat: {
			title: "Todavía no hay chats",
			subtitle: "Envía un mensaje para iniciar la conversación.",
			scroll_tag: "Desplazarte hacia abajo",
			input_placeholder: "Escribe un mensaje...",
			send_button: "Enviar",
			error_title: "Actualiza el chat para que puedas interactuar.",
			error_button: "Actualizar",
			disconnect_title: "Evento terminado",
		},
		product_popup: {
			buy: "Comprar",
			add_cart: "Agregar al carrito",
			added_cart: "¡Producto Agregado!",
		},
		share_message:
			"Te invito a disfrutar de la experiencia de live shopping en",
		shopping_cart: {
			buyButtonText: "Continuar compra",
			budget: "Enviar pedido",
			namePlaceholder: "Ingresa tu nombre*",
			empty: "Todavía no tienes productos",
		},
	},
};
