export const formatRelativeTime = (milliseconds: number): string => {
  const now = new Date().getTime();
  const difference = now - milliseconds;

  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days === 1 ? '1 day ago' : `${days} days ago`;
  } else if (hours > 0) {
    return hours === 1 ? '1h' : `${hours}h`;
  } else if (minutes > 0) {
    return minutes === 1 ? '1m' : `${minutes}m`;
  } else {
    return seconds === 1 ? '1s' : `${seconds}s`;
  }
}

export const isNotEmptyOrWhitespace = (input: string): boolean => {
  if (input == null || input.trim() === '') {
    return false;
  }
  return true;
}