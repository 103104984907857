import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Icon } from "@iconify/react";

import stylesheet from "./RegisterForm.module.scss";
import { useChatContext } from "../../providers/ChatProvider";
import { getUser } from "../../utils/usersUtils";
import { getTextColor } from "../../utils/colorUtils";
import useScreenWidth from "../../hooks/useScreenWidth";
import { saveUserData } from "../../../utils/actions";
import { useTranslation } from "react-i18next";

const {
	Title,
	LoginFormWrapper,
	FormWrapper,
	TextInput,
	SubmitButton,
	CloseButton,
	ButtonTheme,
	formSlideIn,
	formSlideOut,
	backdropShow,
	backdropOut,
	WithError,
	InputWrapper,
	CharCounter,
	DisclaimerWrapper,
	Extra,
} = stylesheet;

interface SchemaShape {
	[key: string]: Yup.StringSchema | Yup.NumberSchema | Yup.BooleanSchema;
}

interface InitialValues {
	[key: string]: string;
}

type FormValues = {
	disclaimerAccepted: boolean;
	[key: string]: string | boolean;
};

interface Props {
	onRegistrationComplete: () => void;
}
const RegisterForm: React.FC<Props> = ({ onRegistrationComplete }) => {
	const { t } = useTranslation();
	const { registerToChat, currentUser, getChatSrc, getChatConfig } =
		useChatContext();
	const src = getChatSrc();
	const { enableLogin, loginForm, mainColor, disclaimerUrl, personalDataUrl } =
		getChatConfig();
	const currentLSUser = getUser(src);

	const screenWidth = useScreenWidth();

	const [userFieldFocused, setUserFieldFocused] = React.useState(false);

	const handleSubmit = async (values: FormValues) => {
		const currentAnonUser = getUser(src);
		try {
			if (currentAnonUser) {
				const formData = {
					name: values["user_0"],
					color: currentAnonUser.color,
					broadcastId: src,
					connectionId: currentAnonUser.id,
					isAnonymous: false,
					userParams: {
						...values,
						username: null,
					},
				};

				await saveUserData(formData);
				return true;
			}
		} catch (error) {
			console.error("ERROR SUBMITING FORM: ", error);
			return false;
		}
		return false;
	};

	const sortedFields = [...(loginForm || [])].sort((a, b) => a.order - b.order);
	const validationSchema = Yup.object(
		sortedFields.reduce<SchemaShape>(
			(acc, field, index) => {
				acc[`${field.fieldLabel}_${index}`] = field.isRequired
					? Yup.string()
							.required("Required")
							.test(
								"not-only-spaces",
								"Cannot be empty or just spaces",
								value => value?.trim() !== ""
							)
					: Yup.string();
				return acc;
			},
			{
				disclaimerAccepted: Yup.boolean().oneOf(
					[true],
					"Disclaimer must be accepted."
				),
			}
		)
	);

	const formik = useFormik<FormValues>({
		initialValues: {
			...sortedFields.reduce<InitialValues>((acc, field, index) => {
				acc[`${field.fieldLabel}_${index}`] = "";
				return acc;
			}, {}),
			disclaimerAccepted: false, // Initial value for disclaimer checkbox
		},
		validationSchema,
		onSubmit: async values => {
			// todo send data to backend
			try {
				await handleSubmit(values);
				if (typeof values["user_0"] === "string") {
					registerToChat(values["user_0"]);
					setIsRegistered(true);
				}
			} catch (error) {
				console.error("TRY AGAIN", error);
			}
		},
	});

	const [isRegistered, setIsRegistered] = useState(
		enableLogin && !currentLSUser?.anonUser
	);
	const [formIsActive, setFormIsActive] = useState(false);
	const [animationClass, setAnimationClass] = useState(formSlideIn);
	const [backdropClass, setBackdropClass] = useState(backdropShow);

	useEffect(() => {
		if (isRegistered) {
			setAnimationClass(formSlideOut);
			setBackdropClass(backdropOut);

			const timerId = setTimeout(() => {
				onRegistrationComplete();
			}, 200);

			return () => clearTimeout(timerId);
		}
	}, [isRegistered, onRegistrationComplete]);

	const getOutlineStyle = (fieldName: string, color: string) => {
		return formik.touched[fieldName]
			? `2px solid ${formik.errors[fieldName] ? "red" : color}`
			: "";
	};

	if (!enableLogin) {
		return null;
	} else if (!formIsActive) {
		return (
			<div
				className={`${LoginFormWrapper} ${backdropClass}`}
				style={{
					backgroundColor: screenWidth > 981 ? "transparent" : "#01020228",
				}}
			>
				<div
					style={{
						backgroundColor: screenWidth > 981 ? "white" : "transparent",
						paddingTop: 30,
					}}
					className={`${FormWrapper} ${animationClass}`}
				>
					<button
						style={{
							height: 44,
							backgroundColor: mainColor,
							color: getTextColor(mainColor),
						}}
						className={`${ButtonTheme} ${SubmitButton}`}
						onClick={() => setFormIsActive(true)}
					>
						{t("components.login_user.participate")}
					</button>
				</div>
			</div>
		);
	} else
		return (
			<div
				style={{ position: screenWidth < 982 ? "fixed" : "absolute" }}
				className={`${LoginFormWrapper}  ${backdropClass}`}
			>
				<div className={`${FormWrapper} ${animationClass}`}>
					<button
						className={CloseButton}
						onClick={() => setFormIsActive(false)}
					>
						<Icon icon="akar-icons:cross" />
					</button>
					<form onSubmit={formik.handleSubmit}>
						<span className={Title}>
							{t("components.login_user.participate")}
						</span>
						{sortedFields.map((field, index) => {
							const fieldValue = formik.values[`${field.fieldLabel}_${index}`];
							const length =
								typeof fieldValue === "string" ? fieldValue.length : 0;

							return (
								<div
									className={InputWrapper}
									key={`${field.fieldLabel}_${index}`}
								>
									<input
										style={{
											outline: getOutlineStyle(
												`${field.fieldLabel}_${index}`,
												mainColor
											),
										}}
										placeholder={
											field.fieldLabel === "user"
												? t("components.login_user.form.user")
												: field.fieldLabel
										}
										className={`${TextInput} ${
											formik.touched[`${field.fieldLabel}_${index}`] &&
											formik.errors[`${field.fieldLabel}_${index}`]
												? WithError
												: ""
										}`}
										type={field.fieldType}
										id={`${field.fieldLabel}_${index}`}
										name={`${field.fieldLabel}_${index}`}
										required={field.isRequired}
										onChange={formik.handleChange}
										onBlur={e => {
											formik.handleBlur(e);
											if (field.fieldLabel === "user")
												setUserFieldFocused(false);
										}}
										onFocus={
											field.fieldLabel === "user"
												? () => setUserFieldFocused(true)
												: undefined
										}
										value={formik.values[
											`${field.fieldLabel}_${index}`
										].toString()}
									/>
									{field.fieldLabel === "user" && userFieldFocused ? (
										<div
											style={{
												backgroundColor:
													formik.touched[`${field.fieldLabel}_${index}`] &&
													formik.errors[`${field.fieldLabel}_${index}`]
														? "red"
														: mainColor,
												color: getTextColor(mainColor),
											}}
											className={CharCounter}
										>
											{length}/30
										</div>
									) : null}
								</div>
							);
						})}
						<div className={DisclaimerWrapper}>
							<input
								type="checkbox"
								id="disclaimerAccepted"
								name="disclaimerAccepted"
								checked={formik.values.disclaimerAccepted}
								onChange={formik.handleChange}
							/>
							{personalDataUrl ? (
								<label htmlFor="disclaimerAccepted">
									<span>{t("components.login_user.disclaimer")}</span>{" "}
									<a href={personalDataUrl} target="_blank" className={Extra}>
										{t("components.login_user.disclaimer_1")}
									</a>{" "}
									<span>{t("components.login_user.disclaimer_2")}</span>{" "}
									<a href={disclaimerUrl} target="_blank">
										{t("components.login_user.disclaimer_3")}
									</a>
								</label>
							) : (
								<label htmlFor="disclaimerAccepted">
									<span>{t("components.login_user.disclaimer_alt")}</span>{" "}
									<a href={disclaimerUrl} target="_blank">
										{t("components.login_user.disclaimer_3")}
									</a>
								</label>
							)}
							{/* {formik.touched.disclaimerAccepted &&
							formik.errors.disclaimerAccepted ? (
								<div>{formik.errors.disclaimerAccepted}</div>
							) : null} */}
						</div>
						<button
							style={{
								backgroundColor: mainColor,
								color: getTextColor(mainColor),
							}}
							className={`${ButtonTheme} ${SubmitButton}`}
							type="submit"
						>
							{t("components.login_user.accept")}
						</button>
					</form>
				</div>
			</div>
		);
};

export default RegisterForm;
