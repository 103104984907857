import { DataAction, ProductType } from "../utils/types";

export const DataReducer = (state: any, { type, payload }: DataAction) => {
	switch (type) {
		case "@UPDATE_PRODUCTS": {
			return { ...state, products: [...payload.products] };
		}
		case "@ADD_PRODUCT": {
			return { ...state, products: [...state.products, payload.product] };
		}
		case "@REMOVE_PRODUCT": {
			return {
				...state,
				products: state.products.filter(
					(product: ProductType) => product.id !== payload.id
				),
			};
		}
		case "@UPDATE_PRODUCT": {
			return {
				...state,
				products: state.products.map((product: ProductType) =>
					product.id === payload.product.id ? payload.product : product
				),
			};
		}
		case "@ADD_POPUP": {
			const productList = state.products.map((product: ProductType) => {
				if (product.id === payload.id) {
					return { ...product, onPopup: true };
				}

				return product;
			});
			return {
				...state,
				products: productList,
			};
		}
		case "@REMOVE_POPUP": {
			const productList = state.products.map((product: ProductType) => {
				if (product.id === payload.id) {
					return { ...product, onPopup: false };
				}

				return product;
			});
			return {
				...state,
				products: productList,
			};
		}
		case "@UPDATE_BROADCAST": {
			return { ...state, broadcast: payload.broadcast };
		}
		case "@UPDATE_CONNECTION": {
			const { connectionId, liveBroadcastingId, userName } = payload;
			return {
				...state,
				queues: {
					...state.queues,
					connectionId,
					liveBroadcastingId,
					userName,
				},
			};
		}
		case "@UPDATE_QUEUES": {
			const { queues } = payload;
			return {
				...state,
				queues: { ...state.queues, queues },
			};
		}
		case "@UPDATE_LIKES": {
			const stats = state.stats;
			return { ...state, stats: { ...stats, likes: payload.count } };
		}
		case "@UPDATE_SHARES": {
			const stats = state.stats;
			return { ...state, stats: { ...stats, shares: payload.count } };
		}
		case "@UPDATE_VIEWS": {
			const stats = state.stats;
			return { ...state, stats: { ...stats, views: payload.count } };
		}
		case "@ADD_SUBSCRIBER": {
			return { ...state, subscriber: payload.subscriber };
		}
		case "@UPDATE_STATUS": {
			return {
				...state,
				broadcast: { ...state.broadcast, status: payload.status },
			};
		}
		case "@UPDATE_VOD": {
			return {
				...state,
				vod: payload.vod,
			};
		}
		case "@UPDATE_ALL": {
			return {
				...state,
				broadcast: payload.broadcast,
				fira_src: payload.fira_src,
				fira_key: payload.fira_key,
				products: payload.products,
				queues: payload.queues,
				dispatch: payload.dispatch,
				shareLink: payload.shareLink,
			};
		}
		default:
			return { ...state };
	}
};
