import * as React from "react";
import { SVGProps } from "react";
interface SVGRProps {
	title?: string;
	titleId?: string;
}

const SvgPlay = ({
	title,
	titleId,
	...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 88 107"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-labelledby={titleId}
		{...props}
	>
		{title ? <title id={titleId}>{title}</title> : null}
		<path
			d="M83.468 61.782 14.274 105.67C8.4 109.39.865 104.885.865 97.384V9.61c0-7.489 7.526-12.006 13.409-8.274l69.194 43.888c1.336.834 2.446 2.039 3.218 3.493a10.224 10.224 0 0 1 1.18 4.787c0 1.681-.407 3.332-1.18 4.786-.772 1.454-1.882 2.66-3.218 3.493Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgPlay;
