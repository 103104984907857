import { Signal } from "../FiraIcons";
import "./MessagePopUp.scss";
import { useTranslation } from "react-i18next";

type MessagePopUpProps =
	| { status: { currentStatus: "NOT_STARTED"; scheduledDate: string } }
	| { status: { currentStatus: "SCHEDULED"; scheduledDate: string } }
	| { status: { currentStatus: "ERROR"; scheduledDate: string } }
	| {
			status: {
				currentStatus: "NOT_FOUND";
				scheduledDate: string;
				storeLogo: string;
			};
	  }
	| {
			status: {
				currentStatus: "RECONNECTING";
				storeLogo: string;
				mainColor: string;
			};
	  }
	| {
			status: {
				currentStatus: "DONE";
				storeLogo: string;
				mainColor: string;
			};
	  };

const getDateMessage = (scheduledDate: string) => {
	const { t } = useTranslation();
	const currentDate: Date = new Date();
	const scheduled_date: Date = new Date(scheduledDate);
	const diffTime = scheduled_date.getTime() - currentDate.getTime();
	const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
	const months = [
		"Enero",
		"Febrero",
		"Marzo",
		"Abril",
		"Mayo",
		"Junio",
		"Julio",
		"Agosto",
		"Septiembre",
		"Octubre",
		"Noviembre",
		"Diciembre",
	];

	let title = "";
	const minutes =
		scheduled_date.getMinutes() < 10
			? `0${scheduled_date.getMinutes()}`
			: scheduled_date.getMinutes();
	const message = `${scheduled_date.getDate()} de ${
		months[scheduled_date.getMonth()]
	} ${scheduled_date.getHours()}:${minutes} hrs.`;

	if (diffHours < 1) {
		title = t("components.message_popup.not_started.soon");
	} else if (diffHours >= 1 && diffHours < 24) {
		title = t("components.message_popup.not_started.hours", {
			hours: diffHours,
		});
	} else {
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		title = t("components.message_popup.not_started.days", {
			days: diffDays,
		});
	}

	return { title, message };
};

export default function MessagePopUp({ status }: any) {
	const { t } = useTranslation();
	const { currentStatus, scheduledDate, storeLogo, mainColor } = status;
	let title = "";
	let message = "";
	const color = mainColor && mainColor !== "" ? mainColor : "#BEEF00";

	switch (currentStatus) {
		case "NOT_STARTED": {
			const content = getDateMessage(scheduledDate);
			title = content.title;
			message = content.message;
			break;
		}
		case "ERROR":
			title = t("components.message_popup.error.title");
			message = t("components.message_popup.error.message");
			break;
		case "NOT_FOUND":
			title = t("components.message_popup.no_broadcast.title");
			message = t("components.message_popup.no_broadcast.message");
			break;
		case "SCHEDULED": {
			const content = getDateMessage(scheduledDate);
			title = content.title;
			message = content.message;
			break;
		}
		case "MISSING_PARAMETERS":
			title = t("components.message_popup.no_parameters.title");
			message = t("components.message_popup.no_parameters.message");
			break;
		case "RECONNECTING":
			title = t("components.message_popup.reconnecting.title");
			break;
		case "DONE":
			title = t("components.message_popup.done.title");
			break;
		case "DONE_ERROR":
			title = t("components.message_popup.done_error.title");
			message = t("components.message_popup.done_error.message");
			break;
		default:
			break;
	}

	return (
		<div className="message_popup_wrapper">
			<div className="message_popup__content">
				<div className="message_popup__content__image">
					{currentStatus == "SCHEDULED" ||
						currentStatus == "NOT_STARTED" ||
						currentStatus == "RECONNECTING" ||
						currentStatus == "DONE" ||
						(currentStatus == "DONE_ERROR" && storeLogo && (
							<img className="storelogo" src={storeLogo} />
						))}
				</div>
				<div className="message_popup__content__text">
					<h4 className="message_popup__content__title">{title}</h4>
					<span className="message_popup__content__text">{message}</span>
				</div>
				<div className="message_popup__content__icon">
					<Signal
						style={{
							color: color,
							fontSize: "50px",
							borderRadius: "50%",
						}}
					/>
				</div>
			</div>
		</div>
	);
}
